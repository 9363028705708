import React from 'react'
import { Button } from 'ui'

interface NavigationButtonsProps {
  currentStep: number
  totalSteps: number
  onNext: () => void
  onPrevious: () => void
  t: (key: string) => string
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  currentStep,
  totalSteps,
  onNext,
  onPrevious,
  t,
}) => (
  <div className="flex gap-8">
    {currentStep !== 1 && (
      <Button
        onClick={onPrevious}
        variant="tertiary"
        size="large"
        label={t('Atrás')}
      />
    )}
    {currentStep !== totalSteps && (
      <Button
        onClick={onNext}
        disabled={currentStep === totalSteps}
        variant="primary"
        size="large"
        label={t('Siguiente')}
      />
    )}
  </div>
)

export default NavigationButtons
