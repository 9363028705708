import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon, Typography } from 'ui'

export const CharactersLimitWarning: FC<{
  createDocument: () => void
}> = ({ createDocument }) => {
  const { t } = useTranslation()

  return (
    <div className="bg-orange-generative-100 flex p-4">
      <div className="pr-4 text-orange-generative-800">
        <Icon icon="alert-circle" size="md" />
      </div>
      <div className="flex flex-col">
        <Typography
          className="font-bold text-orange-generative-800 pb-2"
          variant="small"
        >
          {t('Has llegado al límite de caracteres')}
        </Typography>
        <Typography className="pb-2" variant="small">
          {t(
            'Te ofrecemos sugerencias para los primeros 14.000 caracteres. Puedes crear un nuevo proyecto para el resto del documento.',
          )}
        </Typography>
        <Button
          className="w-fit"
          label={t('Crear nuevo proyecto')}
          leftIcon="file-plus"
          onClick={createDocument}
          size="customSmall"
          variant="secondary"
        />
      </div>
    </div>
  )
}
