import { useTranslation } from 'react-i18next'
import { Button, Input } from 'ui'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { PublicPage, PageHeader } from '../../Components'
import { EMAIL_REGEX_PATTERN } from '../../../utils/constants'
import { useCorrectoApi } from '../../../contexts'
import { useCommonApiErrorMessage } from '../../../hooks'

export const ResetPassword = () => {
  const [email, setEmail] = useState('')

  const correctoApi = useCorrectoApi()
  const { checkForCommonErrorMessage } = useCommonApiErrorMessage()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const resetPasswordHandler = () => {
    if (email.length < 1) return
    if (!new RegExp(EMAIL_REGEX_PATTERN).test(email)) return

    correctoApi
      .sendPasswordResetToken(email)
      .then(response => {
        if (response.ok) {
          navigate('/reset-password-email-sent')
        } else {
          checkForCommonErrorMessage(
            response,
            t(
              'Se ha producido un error al intentar enviar el email de reseteo de contraseña. Averigua que el email sea correcto y vuelve a intentarlo.',
            ),
          )
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }
  const resetPasswordBackHandler = () => {
    navigate('/login')
  }

  return (
    <PublicPage>
      <PageHeader
        subtitle={t(
          'No te preocupes, nos ha pasado a todos. Introduce tu correo y te mandaremos indicaciones para restablecer tu contraseña.',
        )}
        title={t('Restablecer contraseña')}
      />
      <div className="max-w-[368px] w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Input
            label={t('E-mail *')}
            onChange={e => {
              setEmail(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setEmail(e.currentTarget.value)
                resetPasswordHandler()
              }
            }}
            placeholder={t('Escribe tu e-mail')}
            validate={() =>
              email.length > 1
                ? {
                    valid: new RegExp(EMAIL_REGEX_PATTERN).test(email),
                    message: t('El formato del e-mail no es válido'),
                  }
                : {
                    valid: false,
                    message: t('El e-mail es obligatorio'),
                  }
            }
            value={email}
          />
          <Button
            className="-mt-1"
            label={t('Restablecer contraseña')}
            onClick={resetPasswordHandler}
          />
          <Button
            label={t('Volver al inicio de sesión')}
            onClick={resetPasswordBackHandler}
            size="small"
            variant="tertiary"
          />
        </div>
      </div>
    </PublicPage>
  )
}
