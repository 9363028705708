import { Typography } from 'ui'
import { Trans } from 'react-i18next'
import type { FC } from 'react'

export const TermsAndConditions: FC = () => {
  return (
    <div className="mt-[-1px] text-center">
      <Typography className="font-medium" component="p" variant="small">
        <Trans
          components={{
            termsAndConditions: (
              <a
                href="https://www.correcto.es/terminos-y-condiciones-de-uso"
                rel="noopener"
                target="_blank"
              >
                términos y condiciones de uso
              </a>
            ),
            privacyLink: (
              <a
                href="https://www.correcto.es/privacidad"
                rel="noopener"
                target="_blank"
              >
                política de privacidad
              </a>
            ),
            legalLink: (
              <a
                href="https://www.correcto.es/aviso-legal"
                rel="noopener"
                target="_blank"
              >
                aviso legal
              </a>
            ),
            cookiesLink: (
              <a
                href="https://www.correcto.es/cookies"
                rel="noopener"
                target="_blank"
              >
                política de cookies
              </a>
            ),
          }}
          i18nKey="Terms And Conditions"
        />
      </Typography>
    </div>
  )
}
