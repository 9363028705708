import React from 'react'
import { useTranslation } from 'react-i18next'
import { CorrectoLogo, Icon, Typography } from 'ui'

export const PublicFooter: React.FC = () => {
  const year = new Date().getFullYear()
  const { t } = useTranslation()
  return (
    <footer className="flex flex-col gap-8 p-6 md:px-20 md:pb-10 md:pt-0">
      <div className="flex flex-col gap-2">
        <div className="flex h-10 items-center">
          <CorrectoLogo
            href="https://www.correctoai.com/"
            variant="long-blue"
          />
        </div>
        <Typography className="text-purple-ia-800" variant="body2">
          {t('La herramienta que te ayuda a escribir con confianza.')}
        </Typography>
      </div>
      <div className="flex my-4 justify-between">
        <Typography className="text-purple-ia-800" variant="small">
          Copyright © {year} Correcto. {t('Todos los derechos reservados.')}
        </Typography>
        <div className="flex gap-6 text-blue-correcto-800">
          <a
            href="https://www.facebook.com/correctoai"
            rel="noopener"
            target="_blank"
          >
            <Icon icon="facebook-brand" size="sm" />
          </a>
          <a
            href="https://twitter.com/correctoai"
            rel="noopener"
            target="_blank"
          >
            <Icon icon="twitter-x" size="sm" />
          </a>
          <a
            href="https://www.instagram.com/correctoai/"
            rel="noopener"
            target="_blank"
          >
            <Icon icon="instagram" size="sm" />
          </a>
          <a
            href="https://www.linkedin.com/company/correcto-es/mycompany/"
            rel="noopener"
            target="_blank"
          >
            <Icon icon="linkedin" size="sm" />
          </a>
          <a
            href="https://www.tiktok.com/@correctoai"
            rel="noopener"
            target="_blank"
          >
            <Icon icon="tiktok" size="sm" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC-N7giK-GyR8j4PpsqBPkSg"
            rel="noopener"
            target="_blank"
          >
            <Icon icon="youtube" size="sm" />
          </a>
        </div>
      </div>
    </footer>
  )
}
