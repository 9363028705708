import React, { useState } from 'react'
import { Typography } from '../Typography/Typography'
import { Icon } from '../Icon/Icon'
import type { IconName } from '../Icon/Icon'

interface ButtonTagProps {
  label: string
  icon: IconName
  onIconClick?: () => void
}

export const ButtonTag: React.FC<ButtonTagProps> = ({
  label,
  icon,
  onIconClick,
}) => {
  const [hover, setHover] = useState(false)

  return (
    <span
      className="inline-flex gap-2 duration-400 ease-in-out text-neutral-900 hover:bg-neutral-200 font-bold py-1.5 px-3 rounded-full items-center cursor-text select-text"
      data-testid="tag"
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
      role="button"
    >
      <Typography className="capitalize font-normal" variant="body2">
        {label}
      </Typography>
      {hover ? (
        <button
          className="text-blue-correcto-500"
          data-testid="tag-button"
          onClick={onIconClick}
          type="button"
        >
          <Icon icon={icon} size="sm" />
        </button>
      ) : (
        <div className="block w-4 h-4" />
      )}
    </span>
  )
}
