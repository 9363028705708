import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { MetricsCategory, MetricsName, trackEvent } from '../utils/amplitude'
import {
  Footer,
  Header,
  Step1,
  Step2,
  Step3,
  StepIndicator,
} from './Components'
import NavigationButtons from './Components/Navigationbuttons'

const steps = [
  { component: Step1, key: 1 },
  { component: Step2, key: 2 },
  { component: Step3, key: 3 },
]

const Onboarding: React.FC = () => {
  const router = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(location.search)
  const initialStep = parseInt(queryParams.get('step') ?? '1', 10)
  const [step, setStep] = useState(initialStep)

  const userUuid = localStorage.getItem('userUuid')

  useEffect(() => {
    if (step < 1 || step > steps.length) {
      router('/login', { replace: true })
    } else {
      const newUrl = `/onboarding?step=${step.toString()}${
        userUuid ? `&userId=${userUuid}` : ''
      }`
      router(newUrl, { replace: true })
    }

    trackEvent(MetricsCategory.GrowthInteracted, {
      name: MetricsName.OnboardingViewed,
      location: window.location.hostname,
      step,
    })
  }, [step, router, userUuid])

  const handleNext = () => {
    if (step < steps.length) {
      setStep(step + 1)
    }
  }

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1)
    }
  }

  const CurrentStep = steps[step - 1]?.component

  if (!CurrentStep) {
    return null
  }

  return (
    <div className="bg-neutral-100 h-screen w-full flex flex-col px-[116px] py-8">
      <Header />
      <div className="flex flex-col items-center justify-center flex-grow">
        <CurrentStep />
        <StepIndicator
          currentStep={step}
          totalSteps={steps.length}
          onStepClick={setStep}
        />
        <NavigationButtons
          currentStep={step}
          totalSteps={steps.length}
          onNext={handleNext}
          onPrevious={handlePrevious}
          t={t}
        />
      </div>
      <Footer t={t} />
    </div>
  )
}

export default Onboarding
