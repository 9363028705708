import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Ilustration, Typography } from 'ui'

export const EmptyStateNoContent: FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex p-4 m-4 flex-col gap-4 items-center">
      <Typography className="w-[165px] text-center font-medium" variant="small">
        {t('Escribe o pega tu proyecto en el campo de texto.')}
      </Typography>
      <Ilustration ilustration="empty" />
    </div>
  )
}
