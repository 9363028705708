import { Ilustration, Typography } from 'ui'
import { useTranslation } from 'react-i18next'
import { PrivatePage } from '../../Components'

export const CheckoutSuccess = () => {
  const { t } = useTranslation()
  return (
    <PrivatePage>
      <div className="flex flex-col gap-10 items-center">
        <Typography
          className="text-blue-correcto-700 text-center font-bold"
          component="h1"
          variant="subtitle"
        >
          {t('Sácale todo el partido a Correcto')}
        </Typography>
        <div className="flex flex-col gap-12 bg-white, rounded-3xl shadow-1 px-14 py-12 w-[418px]">
          <div className="flex flex-col items-center gap-12">
            <Ilustration ilustration="premium" />
            <div className="flex flex-col gap-4">
              <Typography
                className="text-orange-generative-500 text-center font-bold"
                component="h2"
                variant="body1"
              >
                {t('¡Enhorabuena, ya eres Premium!')}
              </Typography>
              <Typography
                className="text-center font-normal"
                component="p"
                variant="body2"
              >
                {t(
                  'Estamos realmente encantados de que confíes en nosotros. Gracias por unirte a nuestra familia Premium. Ya puedes sacarle todo el partido a Correcto.',
                )}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}
