import { clsx } from 'clsx'
import { type FC } from 'react'
import { Typography } from '../Typography/Typography'

interface MenuItemProps {
  icon: React.ReactNode
  className?: string
  title: string
  active: boolean
  onClick?: () => void
}

export const MenuItem: FC<MenuItemProps> = ({
  icon,
  title,
  active,
  onClick,
  className,
}) => {
  const menuItemClasses = clsx(
    'transition duration-400 ease-in-out rounded-lg text-blue-correcto-700 flex items-center p-4 cursor-pointer w-full',
    {
      'bg-neutral-100': active,
      'hover:bg-neutral-100 active:bg-neutral-100': !active,
    },
    className,
  )

  return (
    <button className={menuItemClasses} onClick={onClick} type="button">
      {icon}
      <Typography className="ml-2 font-semibold" variant="body2">
        {title}
      </Typography>
    </button>
  )
}
