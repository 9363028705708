import type { FC } from 'react'
import premium from './assets/premium.svg'
import deleteAccount from './assets/delete-account.svg'
import empty from './assets/empty.svg'
import identify from './assets/identify.svg'
import documentChecked from './assets/document-checked.svg'
import moreInfo from './assets/more-info.svg'
import group from './assets/group.svg'
import desktopExperience from './assets/desktop_experience.svg'
import chromeExtension from './assets/chrome-extension.svg'
import extensionFolder from './assets/extension-folder.svg'

const ilustrationPath = {
  premium,
  'delete-account': deleteAccount,
  empty,
  identify,
  'document-checked': documentChecked,
  'more-info': moreInfo,
  group,
  'desktop-experience': desktopExperience,
  'chrome-extension': chromeExtension,
  'extension-folder': extensionFolder,
}

export const ILUSTRATIONS: Record<string, IlustrationName> = Object.keys(
  ilustrationPath,
).reduce((acc: Record<string, IlustrationName>, curr) => {
  acc[curr.toUpperCase()] = curr as IlustrationName
  return acc
}, {})

export type IlustrationName = keyof typeof ilustrationPath

interface Ilustration {
  ilustration: IlustrationName
  alt?: string
  className?: string
}

export const Ilustration: FC<Ilustration> = ({
  ilustration,
  alt,
  className = '',
}) => {
  return (
    <img
      alt={alt ?? ilustration}
      className={className}
      src={ilustrationPath[ilustration]}
    />
  )
}
