import {
  Button,
  ButtonTag,
  Ilustration,
  Input,
  ToastType,
  Typography,
  useModal,
} from 'ui'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PrivatePage } from '../../Components'
import { useCorrectoApi, useToast, useUser } from '../../contexts'
import type { DictionaryWord } from '../../clients/CorrectoApiClient'
import { useCommonApiErrorMessage } from '../../hooks'

const useDictionary = () => {
  const correctoApi = useCorrectoApi()
  const modalManager = useModal()
  const userManager = useUser()
  const toastManager = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { checkForCommonErrorMessage } = useCommonApiErrorMessage()

  const [primaryClicked, setPrimaryClicked] = useState<boolean>(false)

  const [word, setWord] = useState<string>('')
  const [words, setWords] = useState<DictionaryWord[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const premiumUser = userManager.user?.is_premium

  const getWords = () => {
    correctoApi
      .getMyDictionary()
      .then(response => {
        setLoading(false)
        if (response.ok) {
          setWords(response.body.results)
        } else {
          toastManager.showToast(
            ToastType.Danger,
            t('Se ha producido un error al obtener las palabras'),
          )
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        setLoading(false)
        throw error
      })
  }

  useEffect(() => {
    if (premiumUser) {
      getWords()
    } else if (premiumUser === false) {
      setLoading(false)
    }
  }, [premiumUser])

  useEffect(() => {
    primaryClicked && addWord(word)
    setPrimaryClicked(false)
    setWord('')
    modalManager.closeModal()
  }, [primaryClicked])

  const addWord = (newWord: string) => {
    correctoApi
      .createDictionaryWord(newWord.toLowerCase())
      .then(response => {
        if (response.ok) {
          setWords([...words, response.body])
        } else {
          checkForCommonErrorMessage(
            response,

            t('Se ha producido un error al añadir la palabra'),
          )
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }

  const addWordHandler = () => {
    if (!document) return
    modalManager.openModal({
      body: (
        <AddWordForm
          onChange={setWord}
          onEnterPressed={() => {
            setPrimaryClicked(true)
          }}
        />
      ),
      title: t('Añadir palabra'),
      subtitle: t('Añade una palabra a tu vocabulario'),
      type: 'interactive',
      primaryAction: t('Guardar'),
      primaryActionType: 'primary',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      titleIcon: 'circle-plus',
      onPrimaryClick: () => {
        setPrimaryClicked(true)
      },
    })
  }

  const deleteWord = (uuid: string) => {
    correctoApi
      .deleteDictionaryWord(uuid)
      .then(response => {
        if (response.ok) {
          setWords(words.filter(({ uuid: wordUuid }) => wordUuid !== uuid))
        } else {
          toastManager.showToast(
            ToastType.Danger,
            t('Se ha producido un error al eliminar la palabra'),
          )
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }

  const deleteWordHandler = (wordToDelete: DictionaryWord) => {
    const title =
      wordToDelete.ignore_word.charAt(0).toUpperCase() +
      wordToDelete.ignore_word.slice(1)
    modalManager.openModal({
      body: (
        <div className="mt-2 mb-6">
          <Typography className="font-normal" variant="body2">
            {t('¿Quieres eliminar esta palabra?')}
          </Typography>
        </div>
      ),
      title,
      type: 'simple',
      primaryAction: t('Borrar palabra'),
      primaryActionType: 'danger',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      onPrimaryClick: () => {
        deleteWord(wordToDelete.uuid)
      },
    })
  }

  const onPremiumClickHandler = () => {
    navigate('/premium')
  }

  return {
    addWordHandler,
    premiumUser,
    words,
    deleteWordHandler,
    onPremiumClickHandler,
    loading,
  }
}

type LetterGroup = [letter: string, words: DictionaryWord[]]

const groupWordsByFirstLetter = (words: DictionaryWord[]): LetterGroup[] => {
  const letters = words.map(({ ignore_word }) => ignore_word[0])
  const uniqueLetters = [...new Set(letters)]
  const groupedWords: LetterGroup[] = uniqueLetters.map((letter: string) => {
    return [
      letter,
      words.filter(({ ignore_word }) => ignore_word.startsWith(letter)),
    ]
  })
  return groupedWords
}

const sortWords = (a: DictionaryWord, b: DictionaryWord) =>
  a.ignore_word.localeCompare(b.ignore_word)

export const Dictionary = () => {
  const { t } = useTranslation()
  const {
    addWordHandler,
    deleteWordHandler,
    premiumUser,
    words,
    onPremiumClickHandler,
    loading,
  } = useDictionary()

  let letterGroup: LetterGroup[] = []

  if (loading) return <PrivatePage />

  if (words.length > 0) {
    letterGroup = groupWordsByFirstLetter(words.sort(sortWords))
  }

  return (
    <PrivatePage>
      <div className="flex flex-col gap-4 grow">
        <div
          className="flex h-full mx-10 flex-col gap-6"
          data-testid="dictionary"
        >
          {premiumUser ? (
            <>
              <div className="flex items-center">
                <div className="grow" />
                <Button
                  label={t('Añadir palabra')}
                  leftIcon="circle-plus"
                  onClick={() => {
                    addWordHandler()
                  }}
                  size="small"
                />
              </div>
              {words.length < 1 ? (
                <EmptyStateDictionary />
              ) : (
                <div className="bg-white min-h-[calc(100vh-196px)] rounded-2xl">
                  <div className="flex flex-col gap-4 px-6 py-10">
                    {/* Word group */}
                    {letterGroup.map(([letter, wordList]) => (
                      <div className="flex flex-col " key={letter}>
                        <div className="w-full px-6 py-2 border-b border-black">
                          <Typography className="font-bold" variant="body2">
                            {letter.toUpperCase()}
                          </Typography>
                        </div>
                        <div className="flex flex-wrap gap-x-2 gap-y-6 px-6 py-4">
                          {wordList.map(word => (
                            <ButtonTag
                              icon="trash"
                              key={word.uuid}
                              label={word.ignore_word}
                              onIconClick={() => {
                                deleteWordHandler(word)
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="flex h-full items-center justify-center bg-white rounded-2xl ">
              <div className="flex flex-col gap-4 items-center">
                <Ilustration ilustration="identify" />
                <Typography
                  className="w-[418px] text-center font-normal"
                  component="p"
                  variant="body2"
                >
                  {t(
                    'Lo sentimos, esta funcionalidad no está disponible con una cuenta Free.',
                  )}
                </Typography>
                <div className="text-center">
                  <Button
                    label={t('Quiero hacerme premium')}
                    leftIcon="crown"
                    onClick={onPremiumClickHandler}
                    size="large"
                    variant="premium"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PrivatePage>
  )
}

interface AddWordFormProps {
  onChange: (s: string) => void
  onEnterPressed: () => void
}

const AddWordForm: FC<AddWordFormProps> = ({ onChange, onEnterPressed }) => {
  const { t } = useTranslation()
  const [word, setWord] = useState<string>('')
  return (
    <Input
      autoFocus
      label={t('Añade una palabra')}
      maxLength={100}
      onChange={e => {
        setWord(e.target.value)
        onChange(e.target.value)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onEnterPressed()
        }
      }}
      placeholder={t('Escribe aquí la palabra')}
      requiredMessage={t(
        'Tienes que escribir una palabra para poder añadirla a tu vocabulario',
      )}
      value={word}
    />
  )
}

const EmptyStateDictionary = () => {
  const { t } = useTranslation()
  return (
    <div className="flex h-full items-center justify-center bg-white rounded-2xl">
      <div className="flex flex-col gap-4 items-center">
        <Ilustration ilustration="identify" />
        <Typography
          className="w-[418px] text-center font-normal"
          component="p"
          variant="body2"
        >
          {t('Actualmente no tienes ninguna palabra añadida a tu vocabulario.')}
        </Typography>
      </div>
    </div>
  )
}
