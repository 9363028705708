import type { FC } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, Typography, useModal } from 'ui'
import type { Scores } from '../../../../clients/CorrectoApiClient'
import {
  MetricsCategory,
  MetricsName,
  trackEvent,
} from '../../../../utils/amplitude'
import { ScoringReport } from '../ScoringReport/ScoringReport'

export interface ScoringPreviewPanelProps {
  scores?: Scores
  overallScore?: number
  overallScoreLoading?: boolean
  scoresLoading: boolean
}

export const ScoringPreviewPanel: FC<ScoringPreviewPanelProps> = ({
  scores,
  overallScore,
  overallScoreLoading = true,
  scoresLoading = true,
}: ScoringPreviewPanelProps) => {
  const { t } = useTranslation()
  const modalManager = useModal()

  const displayReport = useCallback(() => {
    trackEvent(MetricsCategory.FeatureInteracted, {
      name: MetricsName.ReportOpened,
      location: window.location.hostname,
      place: 'Sidebar',
    })

    modalManager.openModal({
      body: scores ? (
        <ScoringReport overallScore={overallScore} scores={scores} />
      ) : null,
      title: t('Informe'),
      type: 'graphic',
      primaryAction: t('Aceptar'),
      primaryActionType: 'primary',
      textAlign: 'left',
      titleAlign: 'left',
      onPrimaryClick: () => {
        modalManager.closeModal()
      },
    })
  }, [modalManager, scores, overallScore])

  return (
    <div className="flex flex-col bg-white h-min w-100 rounded-lg">
      {!overallScoreLoading &&
      !scoresLoading &&
      scores === undefined &&
      overallScore === undefined ? (
        <Typography className="text-neutral-600 font-semibold" variant="small">
          {t('Evaluación no disponible')}
        </Typography>
      ) : (
        <div className="flex justify-end gap-2">
          <Tag
            className="font-semibold text-green-checker-600"
            label={`${t('Claridad')}: ${t(scores?.clarity ? scores?.clarity.display_text.es : 'No disponible')}`}
            variant="gray"
            onClick={displayReport}
          />
          <Tag
            className="font-semibold text-blue-correcto-500"
            label={
              overallScore === undefined
                ? '- / -'
                : `${overallScore.toString()}/100`
            }
            variant="gray"
            onClick={displayReport}
          />
        </div>
      )}
    </div>
  )
}
