import type {
  EditorConfig,
  LexicalNode,
  NodeKey,
  NodeSelection,
  RangeSelection,
  SerializedElementNode,
  Spread,
} from 'lexical'
import {
  addClassNamesToElement,
  removeClassNamesFromElement,
} from '@lexical/utils'
import {
  $applyNodeReplacement,
  $isElementNode,
  $isRangeSelection,
  ElementNode,
} from 'lexical'

export type SerializedRephrasingNode = Spread<
  {
    ids: string[]
  },
  SerializedElementNode
>

export class RephrasingNode extends ElementNode {
  __ids: string[]
  __activeId?: string

  static getType(): string {
    return 'rephrase'
  }

  static clone(node: RephrasingNode): RephrasingNode {
    return new RephrasingNode(Array.from(node.__ids), node.__key)
  }

  static importDOM(): null {
    return null
  }

  static importJSON(serializedNode: SerializedRephrasingNode): RephrasingNode {
    const node = $createRephrasingNode(serializedNode.ids)
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }

  exportJSON(): SerializedRephrasingNode {
    return {
      ...super.exportJSON(),
      ids: this.getIDs(),
      type: 'rephrase',
      version: 1,
    }
  }

  constructor(ids: string[], activeId?: string, key?: NodeKey) {
    super(key)
    this.__ids = ids
    this.__activeId = activeId
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('span')
    element.setAttribute('data-suggestion', this.__ids.join(','))
    addClassNamesToElement(element, config.theme.rephrase)
    if (this.__ids.length > 1) {
      addClassNamesToElement(element, config.theme.rephraseOverlap)
    }
    if (this.__activeId && this.__ids.includes(this.__activeId)) {
      addClassNamesToElement(element, 'active')
    }
    return element
  }

  updateDOM(
    prevNode: RephrasingNode,
    element: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const prevIDs = prevNode.__ids
    const nextIDs = this.__ids
    const prevIDsCount = prevIDs.length
    const nextIDsCount = nextIDs.length
    const overlapTheme = config.theme.suggestionOverlap

    if (prevIDsCount !== nextIDsCount) {
      if (prevIDsCount === 1) {
        if (nextIDsCount === 2) {
          addClassNamesToElement(element, overlapTheme)
        }
      } else if (nextIDsCount === 1) {
        removeClassNamesFromElement(element, overlapTheme)
      }
    }
    return false
  }

  hasID(id: string): boolean {
    const ids = this.getIDs()
    for (let i = 0; i < ids.length; i++) {
      if (id === ids[i]) {
        return true
      }
    }
    return false
  }

  getIDs(): string[] {
    const self = this.getLatest()
    return $isRephrasingNode(self) ? self.__ids : []
  }

  addID(id: string): void {
    const self = this.getWritable()
    if ($isRephrasingNode(self)) {
      const ids = self.__ids
      self.__ids = ids
      for (let i = 0; i < ids.length; i++) {
        // If we already have it, don't add again
        if (id === ids[i]) return
      }
      ids.push(id)
    }
  }

  deleteID(id: string): void {
    const self = this.getWritable()
    if ($isRephrasingNode(self)) {
      const ids = self.__ids
      self.__ids = ids
      for (let i = 0; i < ids.length; i++) {
        if (id === ids[i]) {
          ids.splice(i, 1)
          return
        }
      }
    }
  }

  insertNewAfter(
    selection: RangeSelection,
    restoreSelection = true,
  ): null | ElementNode {
    const element = this.getParentOrThrow().insertNewAfter(
      selection,
      restoreSelection,
    )
    if ($isElementNode(element)) {
      const rephrasingNode = $createRephrasingNode(this.__ids)
      element.append(rephrasingNode)
      return rephrasingNode
    }
    return null
  }

  canInsertTextBefore(): false {
    return false
  }

  canInsertTextAfter(): false {
    return false
  }

  canBeEmpty(): false {
    return false
  }

  isInline(): true {
    return true
  }

  extractWith_Child(
    _child: LexicalNode,
    selection: RangeSelection | NodeSelection,
    destination: 'clone' | 'html',
  ): boolean {
    if (!$isRangeSelection(selection) || destination === 'html') {
      return false
    }
    const anchor = selection.anchor
    const focus = selection.focus
    const anchorNode = anchor.getNode()
    const focusNode = focus.getNode()
    const isBackward = selection.isBackward()
    const selectionLength = isBackward
      ? anchor.offset - focus.offset
      : focus.offset - anchor.offset
    return (
      this.isParentOf(anchorNode) &&
      this.isParentOf(focusNode) &&
      this.getTextContent().length === selectionLength
    )
  }

  excludeFromCopy(destination: 'clone' | 'html'): boolean {
    return destination !== 'clone'
  }
}

export function $createRephrasingNode(
  ids: string[],
  activeId?: string,
): RephrasingNode {
  return $applyNodeReplacement(new RephrasingNode(ids, activeId))
}

export function $isRephrasingNode(
  node: LexicalNode | null,
): node is RephrasingNode {
  return node instanceof RephrasingNode
}
