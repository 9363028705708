import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Button, CorrectoLogo } from 'ui'

export const Navbar = () => {
  const navbarLanguage = useNavbarLanguage()

  return (
    <div className="px-6 pt-6 md:px-20 md:pt-10 md:pb-0 flex gap-4 justify-between">
      <CorrectoLogo href="https://www.correctoai.com/" variant="long-blue" />
      <Button
        label={navbarLanguage}
        onClick={changeLangTo(navbarLanguage)}
        size="small"
        variant="tertiary"
      />
    </div>
  )
}

export const useNavbarLanguage = (): string => {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language.substring(0, 2)
  switch (currentLanguage) {
    case 'en':
      return 'ES'
    case 'es':
      return 'EN'
    default:
      return ''
  }
}

export const changeLangTo = (lang: string) => (): void => {
  switch (lang) {
    case 'ES':
      void changeLanguage('es')
      break
    case 'EN':
      void changeLanguage('en')
      break
    default:
      break
  }
}
