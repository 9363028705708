import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Icon, Input, ToastType, Typography, useModal } from 'ui'
import { PrivatePage } from '../../Components'
import { useCorrectoApi, useToast, useUser } from '../../contexts'
import { trackUserAction } from '../../utils/amplitude'

export const Settings = () => {
  const { updateProfile, changePassword, deleteAccount, user } = useSettings()
  const { t } = useTranslation()
  const modalManager = useModal()
  const correctoApi = useCorrectoApi()
  const userContext = useUser()

  const handleModal = () => {
    modalManager.openModal({
      title: (
        <>
          <p>{t('Vamos a proceder a cancelar tu plan prémium.')}</p>
          <p className="text-center">{t('¿Quieres continuar?')}</p>
        </>
      ),
      type: 'complex',
      primaryAction: t('Si, cancelar plan'),
      primaryActionType: 'primary',
      secondaryAction: t('No, seguir en prémium'),
      textAlign: 'center',
      onPrimaryClick: () => {
        ;(async () => {
          const response = await correctoApi.cancelSubscription()
          trackUserAction({ name: 'Plan-Premium-Cancelled' })
          userContext.setUser(response.body)
        })()
      },
      onClose: modalManager.closeModal,
    })
  }

  if (!user) {
    return null
  }

  return (
    <PrivatePage>
      <div className="mx-10 flex flex-col gap-8">
        <div className="flex justify-end gap-4">
          <Button
            label={t('Modificar contraseña')}
            leftIcon="key"
            onClick={changePassword}
            size="small"
            variant="secondary"
          />
          <Button
            label={t('Editar datos personales')}
            leftIcon="pencil"
            onClick={updateProfile}
            size="small"
            variant="secondary"
          />
        </div>
        <div className="flex gap-4 rounded-2xl bg-white p-6 justify-between">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-6 p-6">
              <Typography className="font-bold" component="h2" variant="body1">
                {t('Datos personales')}
              </Typography>
              <div className="flex flex-col gap-2">
                <Typography
                  className="font-normal"
                  component="h4"
                  variant="body2"
                >
                  {t('Nombre')}
                </Typography>
                <Typography
                  className="font-bold text-ellipsis overflow-hidden whitespace-nowrap w-[500px]"
                  component="p"
                  variant="body2"
                >
                  {user.name}
                </Typography>
              </div>
              <div className="flex flex-col gap-2">
                <Typography
                  className="font-normal"
                  component="h4"
                  variant="body2"
                >
                  {t('Email')}
                </Typography>
                <Typography className="font-bold" component="p" variant="body2">
                  {user.email}
                </Typography>
              </div>
            </div>
            <div className="flex flex-col gap-6 p-6">
              <Typography className="font-bold" component="h2" variant="body1">
                {t('Detalles del plan')}
              </Typography>
              <div className="flex flex-col gap-2">
                <Typography
                  className="font-normal"
                  component="h4"
                  variant="body2"
                >
                  {t('Tipo de plan')}
                </Typography>
                <Typography className="font-bold" component="p" variant="body2">
                  {user.is_premium ? t('Premium') : t('Gratuito')}
                </Typography>
                {user.is_premium ? (
                  <div>
                    {user.cancel_subscription_requested ? (
                      <Button
                        label={t('Cancelación en proceso')}
                        size="small"
                        variant="disabled"
                        disabled
                        className="px-6 py-1"
                      />
                    ) : (
                      <Button
                        label={t('Cancelar plan')}
                        onClick={handleModal}
                        size="small"
                        variant="secondary"
                      />
                    )}
                  </div>
                ) : null}
              </div>
              <div className="flex flex-col gap-2">
                <Typography
                  className="font-normal"
                  component="h4"
                  variant="body2"
                >
                  {t('Fecha de alta')}
                </Typography>
                <Typography className="font-bold" component="p" variant="body2">
                  {new Date(user.created).toLocaleDateString('en-GB')}
                </Typography>
              </div>
              <div>
                <Button
                  label={t('Eliminar cuenta')}
                  leftIcon="trash"
                  onClick={deleteAccount}
                  size="small"
                  variant="danger"
                />
              </div>
            </div>
          </div>

          {!user.is_premium ? <PremiumBanner /> : null}
        </div>
      </div>
    </PrivatePage>
  )
}

const PremiumBanner = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const title = t('Sácale todo el partido a Correcto')
  const content = t(
    'Hazte premium y saca todo el partido a Correcto. Regístrate en un minuto. Cancela cuando quieras.',
  )
  const primaryAction = t('Quiero hacerme Premium')

  const benefitsTitle = t('Disfruta de todo lo que tienes ahora más:')

  const benefits = [t('Mi Vocabulario')]

  const primaryActionHandler = () => {
    navigate('/premium')
  }

  return (
    <div className="flex flex-col gap-10 rounded-2xl p-6 bg-neutral-100">
      <div className="flex flex-col gap-4 w-[446px]">
        <Typography className="font-bold" component="h2" variant="body1">
          {title}
        </Typography>
        <Typography className="font-normal" component="p" variant="body2">
          {content}
        </Typography>
        <div>
          <Button
            label={primaryAction}
            leftIcon="crown"
            onClick={primaryActionHandler}
            size="large"
            variant="premium"
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 w-[446px]">
        <Typography className="font-normal" component="h2" variant="body2">
          {benefitsTitle}
        </Typography>
        <div className="flex flex-col gap-4">
          {benefits.map((benefit, index) => (
            <div
              className="flex gap-5 content-center text-orange-generative-600"
              key={index}
            >
              <Icon icon="circle-check" size="md" />
              <Typography
                className="text-black font-semibold"
                component="span"
                variant="body2"
              >
                {benefit}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

interface ChangeNameFormProps {
  title: string
  onChange: (s: string) => void
  onEnterPressed: () => void
}

const ChangeNameForm: FC<ChangeNameFormProps> = ({
  title,
  onChange,
  onEnterPressed,
}) => {
  const { t } = useTranslation()
  const [newTitle, setNewTitle] = useState<string>(title)
  return (
    <Input
      label={t('Nombre')}
      maxLength={100}
      onChange={e => {
        setNewTitle(e.target.value)
        onChange(e.target.value)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onEnterPressed()
        }
      }}
      requiredMessage={t('El nombre es obligatorio')}
      value={newTitle}
    />
  )
}

const useSettings = () => {
  const [newName, setNewName] = useState<string>('')
  const [primaryClicked, setPrimaryClicked] = useState<boolean>(false)
  const [changePasswordPrimaryClicked, setChangePasswordPrimaryClicked] =
    useState<boolean>(false)

  const [deleteAccountPrimaryClicked, setDeleteAccountPrimaryClicked] =
    useState<boolean>(false)

  const modalManager = useModal()
  const correctoApi = useCorrectoApi()
  const toastManager = useToast()
  const userManager = useUser()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const updateUser = (name: string) => {
    if (!userManager.user) return
    if (!name) return
    correctoApi
      .updateUser(userManager.user.uuid, name)
      .then(response => {
        if (response.ok) {
          userManager.setUser(response.body)
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al actualizar tus datos personales'),
        )
        throw error
      })
  }
  const resetPassword = () => {
    if (!userManager.user) return
    correctoApi
      .sendPasswordResetToken(userManager.user.email)
      .then(response => {
        if (response.ok) {
          userManager.logout()
          navigate('/reset-password-email-sent')
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t(
            'Se ha producido un error al enviar el email de reseteo de contraseña',
          ),
        )
        throw error
      })
  }

  const deleteUser = () => {
    if (!userManager.user) return
    correctoApi
      .deleteAccount(userManager.user.uuid)
      .then(response => {
        if (response.ok) {
          window.location.href = 'https://www.correcto.es/feedback'
          userManager.logout()
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al eliminar tu cuenta'),
        )
        throw error
      })
  }

  useEffect(() => {
    primaryClicked && updateUser(newName)
    setPrimaryClicked(false)
    modalManager.closeModal()
  }, [primaryClicked])

  useEffect(() => {
    changePasswordPrimaryClicked && resetPassword()
    setChangePasswordPrimaryClicked(false)
    modalManager.closeModal()
  }, [changePasswordPrimaryClicked])

  useEffect(() => {
    deleteAccountPrimaryClicked && deleteUser()
    setDeleteAccountPrimaryClicked(false)
    modalManager.closeModal()
  }, [deleteAccountPrimaryClicked])

  const updateProfile = () => {
    if (!userManager.user) return

    modalManager.openModal({
      body: (
        <ChangeNameForm
          onChange={setNewName}
          onEnterPressed={() => {
            setPrimaryClicked(true)
          }}
          title={userManager.user.name}
        />
      ),
      title: t('Editar datos personales'),
      type: 'interactive',
      primaryAction: t('Guardar cambios'),
      primaryActionType: 'primary',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      titleIcon: 'pencil',
      onPrimaryClick: () => {
        setPrimaryClicked(true)
      },
    })
  }

  const changePassword = () => {
    if (!userManager.user) return

    modalManager.openModal({
      body: (
        <div className="flex flex-col items-center gap-2 px-6">
          <Typography
            className="text-black font-normal"
            component="p"
            variant="body2"
          >
            {t(
              'No te preocupes, nos ha pasado a todos. Te enviaremos un correo para que puedas restablecer fácilmente tu contraseña de acceso.',
            )}
          </Typography>
          <div className="p-4 w-[600px] bg-neutral-100">
            <Typography
              className="text-black font-medium"
              component="p"
              variant="small"
            >
              {t(
                'Si confirmas que quieres restablecer tu contraseña procederemos a cerrar la sesión en este navegador para que al volver puedas ingresar con la nueva contraseña que hayas creado.',
              )}
            </Typography>
          </div>
        </div>
      ),
      title: t('¿Quieres restablecer tu contraseña?'),
      type: 'graphic',
      primaryAction: t('Restablecer contraseña'),
      primaryActionType: 'primary',
      primaryIcon: 'key',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      onPrimaryClick: () => {
        setChangePasswordPrimaryClicked(true)
      },
    })
  }

  const deleteAccount = () => {
    if (!userManager.user) return

    modalManager.openModal({
      body: (
        <div className="flex flex-col items-center gap-2 px-6 w-[600px]">
          <Typography
            className="text-black font-normal"
            component="p"
            variant="body2"
          >
            {t(
              'Correcto es la mejor herramienta para ayudarte a que tus textos sean excelentes. ¿Estás seguro de que quieres renunciar a que seamos tu asistente de escritura?',
            )}
          </Typography>
          <div className="p-4 bg-neutral-100">
            <Typography
              className="text-black font-medium"
              component="p"
              variant="small"
            >
              {t(
                'Eliminando tu cuenta perderás el derecho de uso de la plataforma y daremos de baja el cobro de la suscripción. Esta acción es irrevocable.',
              )}
            </Typography>
          </div>
        </div>
      ),
      title: t('¿Quieres eliminar tu cuenta?'),
      type: 'graphic',
      primaryAction: t('Eliminar cuenta'),
      primaryActionType: 'danger',
      primaryIcon: 'trash',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      onPrimaryClick: () => {
        setDeleteAccountPrimaryClicked(true)
      },
    })
  }

  return {
    updateProfile,
    changePassword,
    deleteAccount,
    user: userManager.user,
  }
}
