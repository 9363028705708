import type { FC } from 'react'
import { clsx } from 'clsx'
import { Typography } from '../Typography/Typography'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'premium'
  | 'danger'
  | 'suggestion'
  | 'discard'
  | 'link'
  | 'disabled'

export type ButtonSize = 'large' | 'small' | 'customSmall'

interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  label: string
  leftIcon?: IconName
  rightIcon?: IconName
  className?: string
  disabled?: boolean
  onClick?: () => void
}

const baseClasses =
  'inline-flex items-center justify-center font-medium transition duration-400 ease-in-out focus:outline-none rounded-3xl'
const largeClasses = 'px-8 py-3'
const smallClasses = 'px-6 py-2 font-normal text-sm'
const specialClasses = 'px-[22px] py-[6px] font-normal text-sm'
const primaryClasses =
  'bg-blue-correcto-500 text-white hover:bg-blue-correcto-600 active:bg-blue-correcto-700'
const secondaryClasses = 'button-secondary border-secondary border-2'
const tertiaryClasses =
  'text-blue-correcto-500 hover:bg-purple-ia-100 hover:text-blue-correcto-700 active:text-blue-correcto-700 active:bg-purple-ia-00'
const premiumClasses = 'text-neutral-100 button-premium font-semibold'
const dangerClasses =
  'bg-red-extension-500 text-white hover:bg-red-extension-800 active:bg-red-extension-700'
const suggestionClasses =
  'bg-green-checker-300 hover:bg-green-checker-500 active:bg-green-checker-700 text-black py-1.5 px-[11px] rounded-2xl'
const discardClasses =
  'text-neutral-700 hover:bg-neutral-200 active:bg-neutral-400 py-1.5 px-2 rounded-2xl'
const linkClasses = 'over:bg-cyan-600'
const disabledClasses =
  'bg-neutral-100 text-neutral-500 border-neutral-300 border-2 cursor-not-allowed'

export const Button: FC<ButtonProps> = ({
  variant = 'primary',
  size = 'large',
  label,
  leftIcon,
  rightIcon,
  className,
  disabled,
  onClick,
}) => {
  const buttonClassName = clsx(className, baseClasses, {
    [largeClasses]: size === 'large',
    [smallClasses]:
      size === 'small' && !['suggestion', 'discard', 'link'].includes(variant),
    [specialClasses]:
      size === 'customSmall' &&
      !['suggestion', 'discard', 'link'].includes(variant),
    [primaryClasses]: variant === 'primary',
    [secondaryClasses]: variant === 'secondary',
    [tertiaryClasses]: variant === 'tertiary',
    [premiumClasses]: variant === 'premium',
    [dangerClasses]: variant === 'danger',
    [suggestionClasses]: variant === 'suggestion',
    [discardClasses]: variant === 'discard',
    [linkClasses]: variant === 'link',
    [disabledClasses]: variant === 'disabled',
  })

  const getTextVariant = (s: ButtonSize, v: string) => {
    if (s === 'small' || s === 'customSmall') return 'small'
    if (v === 'premium') return 'body2'
    return 'body2'
  }

  return (
    <button
      className={buttonClassName}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {leftIcon ? (
        <span className="pr-1">
          <Icon icon={leftIcon} size={size === 'large' ? 'md' : 'sm'} />
        </span>
      ) : null}
      <Typography variant={getTextVariant(size, variant)}>{label}</Typography>
      {rightIcon ? (
        <span className="pl-1">
          <Icon icon={rightIcon} size={size === 'large' ? 'md' : 'sm'} />
        </span>
      ) : null}
    </button>
  )
}
