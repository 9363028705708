import type { FC, ReactNode } from 'react'
import { clsx } from 'clsx'
import { Button } from '../Button/Button'
import { Typography } from '../Typography/Typography'
import type { IlustrationName } from '../Ilustration/Ilustration'
import { Ilustration } from '../Ilustration/Ilustration'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'
import circularStrokes from '../assets/circularStrokes.svg'

interface ModalProps {
  title: string | ReactNode
  ilustration?: IlustrationName
  primaryAction: string
  primaryIcon?: IconName
  secondaryAction?: string
  onClose: () => void
  onPrimaryClick: () => void
  onSecondaryClick: () => void
  children: ReactNode
  className?: string
  primaryActionType?: 'primary' | 'danger' | 'premium'
  textAlign: 'center' | 'left'
  titleAlign?: 'center' | 'left'
}

export const GraphicModal: FC<ModalProps> = ({
  title,
  ilustration,
  primaryAction,
  primaryIcon,
  secondaryAction,
  onClose,
  onPrimaryClick,
  onSecondaryClick,
  children,
  className,
  primaryActionType = 'premium',
  textAlign = 'center',
  titleAlign,
}) => {
  const containerClassName = clsx(
    'graphic-modal flex relative rounded-2xl bg-white border border-neutral-300 w-[802px]',
    className ?? null,
  )
  const rightSideClassName = clsx(
    'flex flex-col flex-1 rounded-r-2xl gap-6 justify-center p-10',
    {
      'text-center items-center': textAlign === 'center',
      'text-left items-left': textAlign === 'left',
    },
  )
  return (
    <div className={containerClassName}>
      <div className="flex w-full relative gap-2">
        {ilustration ? (
          <div className="left-side w-[320px]">
            <img
              alt="circular strokes"
              className="relative top-[4px] left-[4px]"
              height="268px"
              src={circularStrokes}
              width="268px"
            />
            <Ilustration className="absolute" ilustration={ilustration} />
          </div>
        ) : null}

        <div className={rightSideClassName}>
          <div className="flex flex-col gap-2">
            <Typography
              className={clsx('title font-bold', {
                'text-blue-correcto-700': primaryActionType !== 'danger',
                'text-red-extension-500': primaryActionType === 'danger',
                'self-center': titleAlign === 'center',
                'self-left': titleAlign === 'left',
              })}
              component="h2"
              variant="subtitle"
            >
              {title}
            </Typography>
            {children}
          </div>
          <div className="flex flex-col gap-2 items-center">
            <Button
              label={primaryAction}
              leftIcon={primaryIcon}
              onClick={onPrimaryClick}
              size="large"
              variant={primaryActionType}
            />
            {secondaryAction ? (
              <Button
                label={secondaryAction}
                onClick={onSecondaryClick}
                size="small"
                variant="tertiary"
              />
            ) : null}
          </div>
        </div>
      </div>
      <button
        className="fill-neutral-600 absolute top-4 right-4"
        onClick={onClose}
        type="button"
      >
        <Icon icon="close" size="sm" />
      </button>
    </div>
  )
}
