import { useLocation, useNavigate } from 'react-router-dom'
import { Icon, MenuItem, Sidebar } from 'ui'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../contexts'
import { LOCAL_STORAGE_AUTH_TYPE } from '../../clients/CorrectoApiClient'
import { LanguageSwitcher } from '../PrivateNavbar/PrivateNavbar'

export const PrivateSidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const userManager = useUser()
  const { t } = useTranslation()

  const logoutHandler = () => {
    userManager.logout()
    navigate('/login')
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TYPE)
  }

  const showPremium =
    userManager.user !== undefined &&
    userManager.user !== null &&
    !userManager.user?.is_premium

  return (
    <Sidebar
      isPremium={userManager.user?.is_premium}
      logoutHandler={logoutHandler}
      logoutText={t('Cerrar sesión')}
      LanguageSwitcher={LanguageSwitcher}
    >
      <MenuItem
        active={location.pathname === '/projects'}
        icon={<Icon icon="file" size="md" />}
        onClick={() => {
          navigate('/projects')
        }}
        title={t('Proyectos')}
      />
      <MenuItem
        active={location.pathname === '/dictionary'}
        icon={<Icon icon="book" size="md" />}
        onClick={() => {
          navigate('/dictionary')
        }}
        title={t('Mi vocabulario')}
      />
      <MenuItem
        active={location.pathname === '/settings'}
        icon={<Icon icon="settings" size="md" />}
        onClick={() => {
          navigate('/settings')
        }}
        title={t('Ajustes')}
      />
      {showPremium ? (
        <MenuItem
          active={location.pathname === '/premium'}
          className="text-orange-generative-600"
          icon={<Icon icon="crown" size="md" />}
          onClick={() => {
            navigate('/premium')
          }}
          title={t('Prémium')}
        />
      ) : null}
    </Sidebar>
  )
}
