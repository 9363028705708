import { type FC } from 'react'
import { Button } from '../Button/Button'
import { IconButton } from '../IconButton/IconButton'
import type { IlustrationName } from '../Ilustration/Ilustration'
import { Ilustration } from '../Ilustration/Ilustration'
import { Typography } from '../Typography/Typography'
import banner_bg from '../assets/banner_bg.svg'

interface BannerProps {
  title: string
  description: string
  buttonText: string
  onClick: () => void
  onClose: () => void
  ilustration: IlustrationName
  leftIcon: string
  hasBgImage?: boolean
}

export const Banner: FC<BannerProps> = ({
  description,
  title,
  buttonText,
  onClick,
  onClose,
  ilustration,
  leftIcon,
  hasBgImage,
}) => {
  return (
    <div
      style={{
        backgroundImage: hasBgImage ? `url(${String(banner_bg)})` : '',
        boxShadow: '0px 4px 8px 0px rgba(12, 26, 102, 0.30)',
      }}
      className={`relative
        ${hasBgImage ? 'bg-no-repeat bg-cover bg-center ' : 'bg-white'}
        h-[120px] rounded-2xl w-full overflow-hidden`}
    >
      <div className="grid grid-cols-3 py-8 pl-8 pr-14">
        <div className="col-span-2 flex items-center">
          <span className="pr-1 mr-4">
            <Ilustration ilustration={ilustration} />
          </span>
          <div>
            <Typography
              className={`${hasBgImage ? 'text-white' : 'blue-correcto-800'} text-ellipsis overflow-hidden whitespace-nowrap font-bold`}
              component="p"
              variant="body1"
            >
              {title}
            </Typography>
            <Typography
              className={`${hasBgImage ? 'text-white' : 'blue-correcto-800'} text-ellipsis overflow-hidden whitespace-nowrap`}
              component="p"
              variant="small"
            >
              {description}
            </Typography>
          </div>
        </div>
        <div className="flex items-center justify-end relative">
          <Button
            label={buttonText}
            leftIcon={leftIcon}
            onClick={onClick}
            size="large"
            variant={hasBgImage ? 'secondary' : 'primary'}
          />
        </div>
        <IconButton
          className="top-2 right-2 absolute"
          icon="close"
          onClick={onClose}
          variant="neutral"
        />
      </div>
    </div>
  )
}
