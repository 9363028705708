import {
  LexicalComposer,
  type InitialConfigType,
  type InitialEditorStateType,
} from '@lexical/react/LexicalComposer'
import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastType } from 'ui'
import { useDebouncedCallback } from 'use-debounce'
import { v4 as uuidv4 } from 'uuid'
import {
  SuggestionType,
  type Document,
  type Scores,
} from '../../clients/CorrectoApiClient'
import { PrivatePage } from '../../Components'
import { useCorrectoApi, useToast, useUser } from '../../contexts'
import { MetricsCategory, MetricsName, trackEvent } from '../../utils/amplitude'
import { EMPTY_CONTENT, MIN_CHARACTERS_REPHRASING } from '../../utils/constants'
import { captureException } from '../../utils/sentry'
import { RichTextEditor, SuggestionPannel, TitleBar } from './Components'
import {
  RephrasingNode,
  SuggestionNode,
} from './Components/RichTextEditor/Nodes'
import { ToolbarPlugin } from './Components/RichTextEditor/Plugins'

interface EditorRephraseSuggestion {
  id: string
  main_original_sentence: string
  paraphrase_alternative: string
  offset: number
  type: { typeName: (typeof SuggestionType)['RephraseSuggestion'] }
  context: {
    text: string
    offset: number
    length: number
  }
  length: number
}

interface SuggestionReplacement {
  suggestion: string
  id: string
}

const useEditProject = () => {
  const { projectUuid } = useParams()

  const [document, setDocument] = useState<Document>()

  const [checkLimited, setCheckLimited] = useState<boolean>(false)
  const [suggestions, setSuggestions] = useState<any[] | null>()
  const [rephraseSuggestions, setRephraseSuggestions] = useState<
    EditorRephraseSuggestion[] | null
  >()
  const [allSuggestions, setAllSuggestions] = useState<any[] | null>()

  const [
    isFirstRephraseSuggestionsRetrieval,
    setIsFirstRephraseSuggestionsRetrieval,
  ] = useState<boolean>(true)

  const correctoApi = useCorrectoApi()
  const toastManager = useToast()
  const { t } = useTranslation()
  const userManager = useUser()

  const [activeSuggestion, setActiveSuggestion] = useState<string>('')
  const [suggestionReplacement, setSuggestionReplacement] =
    useState<SuggestionReplacement>()
  const [isLoadingRephraseSuggestions, setIsLoadingRephraseSuggestions] =
    useState<boolean>(false)
  const [updateActiveSuggestion, setUpdateActiveSuggestion] = useState(false)
  const [
    selectionActiveRephraseSuggestion,
    setSelectionActiveRephraseSuggestion,
  ] = useState<string>()

  const [scores, setScores] = useState<Scores>()
  const [overallScore, setOverallScore] = useState<number | undefined>()
  const [overallScoreLoading, setOverallScoreLoading] = useState(false)
  const [scoresLoading, setScoresLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const filteredRephraseSuggestions = (rephraseSuggestions ?? []).filter(
      rephraseSuggestion =>
        document?.content.includes(rephraseSuggestion.context.text),
    )

    const filteredSuggestions = (suggestions ?? []).filter(
      s =>
        filteredRephraseSuggestions?.reduce(
          (acc: boolean, curr) =>
            acc &&
            !(curr.offset <= s.offset && s.offset <= curr.offset + curr.length),
          true,
        ) ?? true,
    )

    const localSuggestions = [
      ...filteredSuggestions,
      ...filteredRephraseSuggestions,
    ].sort((a, b) => a.offset - b.offset)

    setAllSuggestions(localSuggestions)

    if (updateActiveSuggestion) {
      let activeSuggestionId

      if (selectionActiveRephraseSuggestion) {
        activeSuggestionId = selectionActiveRephraseSuggestion
      } else {
        activeSuggestionId =
          localSuggestions.length > 0 ? localSuggestions[0].id : ''
      }
      setActiveSuggestion(activeSuggestionId)
      setUpdateActiveSuggestion(false)
      setSelectionActiveRephraseSuggestion('')
    }
  }, [suggestions, rephraseSuggestions, document?.content])

  const getTypingSuggestions = () => {
    const content = document?.content.replace(/\n+/g, '\n')

    if (!content || content?.trimStart().length < 1) {
      setSuggestions(null)
      return
    }
    setOverallScoreLoading(true)
    correctoApi
      .getTypingAssistantSuggestions(content)
      .then(response => {
        if (response.ok) {
          setSuggestions(
            response.body?.matches.map(suggestion => ({
              ...suggestion,
              id: uuidv4(),
            })),
          )
          setOverallScore(response.body?.score)

          setUpdateActiveSuggestion(true)

          if (!checkLimited && response.body?.check_limited) {
            toastManager.showToast(
              ToastType.Danger,
              t(
                'Has excedido el límite de caracteres disponibles para corregir',
              ),
            )
          }

          setCheckLimited(response.body?.check_limited)
        } else {
          if (response.status === 422) return
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al intentar obtener las sugerencias'),
        )
        throw error
      })
      .finally(() => {
        setOverallScoreLoading(false)
      })
  }

  const showLimitReachedToast = useCallback(() => {
    toastManager.showToast(
      ToastType.Warning,
      <p className="flex gap-1">
        {t('Has llegado al máximo de sugerencias.')}
        <button
          className="font-medium underline"
          onClick={() => navigate('/premium')}
        >
          {t('¡Escribe sin límites!')}
        </button>
      </p>,
      undefined,
      'crown',
    )
  }, [t, navigate, toastManager])

  const getRephraseWritingSuggestions = ({
    selected_text,
  }: {
    selected_text?: string
  }) => {
    const content = document?.content.replace(/\n+/g, '\n')
    if (
      !content ||
      (!selected_text && content.length < MIN_CHARACTERS_REPHRASING)
    ) {
      setRephraseSuggestions(null)
      return
    }

    setIsLoadingRephraseSuggestions(true)
    correctoApi
      .getRephraseWritingAssistantSuggestions({ text: content })
      .then(response => {
        if (response.ok) {
          setRephraseSuggestions(
            response.body?.suggestions.map(suggestion => ({
              ...suggestion,
              id: uuidv4(),
              type: { typeName: SuggestionType.RephraseSuggestion },
              context: {
                text: suggestion.main_original_sentence,
                offset: suggestion.offset,
                length: suggestion.length,
              },
              length: suggestion.length,
            })) as EditorRephraseSuggestion[],
          )
          setUpdateActiveSuggestion(true)
          setIsFirstRephraseSuggestionsRetrieval(false)
        } else if (response.status === 429) {
          showLimitReachedToast()
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al intentar obtener las sugerencias'),
        )
        throw error
      })
      .finally(() => {
        setIsLoadingRephraseSuggestions(false)
      })
  }

  const getRephraseWritingSuggestionsForSelection = ({
    selected_text,
  }: {
    selected_text: string
  }) => {
    const content = document?.content.replace(/\n+/g, '\n')
    if (!content) {
      setRephraseSuggestions(null)
      return
    }

    const paragraphs = selected_text
      .split(/\r\n|\r|\n/)
      .filter(paragraphText => Boolean(paragraphText))

    setIsLoadingRephraseSuggestions(true)
    let resolvedRequests = 0
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Our API is awaiting the requests, hence there's no point in saving a reference to them as they'll alreay be resolved when this following line is executed. We need to have a discussion around this
    paragraphs.forEach(selectedText =>
      correctoApi
        .getRephraseWritingAssistantSuggestions({
          text: content,
          selected_text: selectedText,
        })
        .then(response => {
          resolvedRequests++
          if (response.ok) {
            const mappedSuggestions = response.body?.suggestions.map(
              suggestion => ({
                ...suggestion,
                id: uuidv4(),
                type: { typeName: SuggestionType.RephraseSuggestion },
                context: {
                  text: suggestion.main_original_sentence,
                  offset: suggestion.offset,
                  length: suggestion.length,
                },
                length: suggestion.length,
              }),
            )
            setRephraseSuggestions(currentRephraseSuggestions => {
              return [
                ...(currentRephraseSuggestions ?? []),
                ...mappedSuggestions,
              ] as EditorRephraseSuggestion[]
            })
            setUpdateActiveSuggestion(true)
            if (mappedSuggestions?.length) {
              setSelectionActiveRephraseSuggestion(mappedSuggestions[0].id)
            }
          } else if (response.status === 429) {
            showLimitReachedToast()
          } else {
            throw new Error(JSON.stringify(response))
          }
        })
        .catch((error: unknown) => {
          toastManager.showToast(
            ToastType.Danger,
            t('Se ha producido un error al intentar obtener las sugerencias'),
          )
          throw error
        })
        .finally(() => {
          if (resolvedRequests === paragraphs.length) {
            setIsLoadingRephraseSuggestions(false)
          }
        }),
    )
  }

  const getDocumentDetails = () => {
    if (!projectUuid) return
    correctoApi
      .getDocument(projectUuid)
      .then(response => {
        if (response.ok) {
          if (response.body) {
            setDocument(response.body)
          }
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al obtener el documento'),
        )
        throw error
      })
  }

  const updateDocument = ({
    uuid = document?.uuid,
    title = document?.title,
    content = document?.content ?? '',
    content_formats = document?.content_formats,
  }: Partial<Document>) => {
    if (!uuid || !title || !content_formats) return
    if (
      JSON.stringify(content_formats) ===
        JSON.stringify(document?.content_formats) &&
      title === document?.title
    )
      return
    correctoApi
      .updateDocument(uuid, title, content, content_formats)
      .then(response => {
        if (response.ok) {
          if (response.body) {
            setDocument(response.body)
          }
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al actualizar el proyecto'),
        )
        throw error
      })
  }

  const createDocument = () => {
    const newDocumentName = t('Nuevo proyecto')
    correctoApi
      .createDocument(newDocumentName, '', JSON.parse(EMPTY_CONTENT) as object)
      .then(response => {
        if (response.ok) {
          navigate(`/projects/${response.body?.uuid}`)
          navigate(0)
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t(
            'No hemos podido crear un nuevo proyecto. Por favor, inténtalo de nuevo en unos minutos.',
          ),
        )
        throw error
      })
  }

  const updateContext = (newDocument: Partial<Document>) => {
    updateDocument(newDocument)
    getScores()
  }

  const debouncedContentChangeHandler = useDebouncedCallback(
    updateContext,
    1000,
  )

  const recalculateRephraseOffset = (newDocument: Partial<Document>) => {
    if (!suggestionReplacement) return
    const content = (newDocument.content ?? '').replace(/\n+/g, '\n')
    setRephraseSuggestions(s => {
      if (!s) return
      return s.map(suggestion => {
        const offset = content.indexOf(suggestion.context.text)
        return {
          ...suggestion,
          offset,
          context: {
            ...suggestion.context,
            offset,
          },
        }
      })
    })
  }

  const onContentChangeHandler = (newDocument: Partial<Document>) => {
    if (newDocument.content?.length === 0) {
      setRephraseSuggestions([])
      setSuggestions([])
      setScores(undefined)
      setOverallScore(undefined)
      updateDocument(newDocument)
    }

    if (suggestionReplacement) {
      recalculateRephraseOffset(newDocument)

      updateDocument(newDocument)
      setSuggestionReplacement(undefined)
      getScores()
    } else {
      debouncedContentChangeHandler(newDocument)
    }
  }
  const onTitleChangeHandler = (title: string) => {
    updateDocument({ title })
  }

  const onSuggestionSelectHandler = (
    replacement: string,
    suggestionIndex: number,
  ) => {
    const suggestion = allSuggestions?.[suggestionIndex]
    if (suggestion) {
      trackEvent(MetricsCategory.SuggestionInteracted, {
        name: MetricsName.GrammarAccepted,
        location: window.location.hostname,
        place: 'Sidebar',
      })
    }

    setSuggestionReplacement({
      suggestion: replacement,
      id: suggestion?.id,
    })
    setActiveSuggestion('')
    removeTypingSuggestion(suggestionIndex)
  }

  const removeRephraseSuggestion = (suggestionIndex: number) => {
    const suggestion = allSuggestions?.[suggestionIndex]
    const currentRephraseSuggestionsClone = [...(rephraseSuggestions ?? [])]
    const rephraseOnlySuggestionIndex = rephraseSuggestions?.findIndex(
      suggestionToRemove => suggestion.id === suggestionToRemove.id,
    )
    if (
      rephraseOnlySuggestionIndex !== undefined &&
      rephraseOnlySuggestionIndex >= 0
    ) {
      currentRephraseSuggestionsClone?.splice(rephraseOnlySuggestionIndex, 1)
    }

    setRephraseSuggestions(currentRephraseSuggestionsClone)
  }

  const removeTypingSuggestion = (suggestionIndex: number) => {
    const suggestion = allSuggestions?.[suggestionIndex]
    const currentTypingSuggestionsClone = [...(suggestions ?? [])]
    const typingOnlySuggestionIndex = suggestions?.findIndex(
      suggestionToRemove => suggestion.id === suggestionToRemove.id,
    )
    if (
      typingOnlySuggestionIndex !== undefined &&
      typingOnlySuggestionIndex >= 0
    ) {
      currentTypingSuggestionsClone?.splice(typingOnlySuggestionIndex, 1)
    }

    setSuggestions(currentTypingSuggestionsClone)
  }

  const onRephraseSuggestionSelectHandler = (
    replacement: string,
    suggestionIndex: number,
    id: string,
  ) => {
    const rephraseSuggestion = allSuggestions?.find(
      suggestion => suggestion.id === id,
    )
    if (rephraseSuggestion) {
      trackEvent(MetricsCategory.SuggestionInteracted, {
        name: MetricsName.RephraseAccepted,
        location: window.location.hostname,
        place: 'Sidebar',
      })
    }

    setSuggestionReplacement({
      suggestion: replacement,
      id,
    })
    setActiveSuggestion('')
    removeRephraseSuggestion(suggestionIndex)
  }

  const onRephraseSuggestionDiscardHandler = (suggestionIndex: number) => {
    removeRephraseSuggestion(suggestionIndex)
  }

  const onSelectionRephraseHandler = (selection: string) => {
    getRephraseWritingSuggestionsForSelection({ selected_text: selection })
  }

  const getScores = useCallback(() => {
    const content = document?.content.replace(/\n+/g, '\n')
    if (!content || content.length < 1) {
      return
    }

    setScoresLoading(true)
    correctoApi
      .getScores({ text: content })
      .then(response => {
        if (response.ok) {
          setScores(response.body?.scores)
        } else {
          if (response.status === 422) return
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t(
            'Se ha producido un error al intentar obtener la evaluación del texto',
          ),
        )
        throw error
      })
      .finally(() => {
        setScoresLoading(false)
      })
  }, [overallScore])

  useEffect(getDocumentDetails, [])
  useEffect(() => {
    if (isFirstRephraseSuggestionsRetrieval) {
      getRephraseWritingSuggestions({})
    }
  }, [document?.content])
  useEffect(getTypingSuggestions, [document?.content])

  useEffect(getScores, [document])

  return {
    document,
    documentTitle: document?.title,
    documentContentLength: document?.content.length ?? 0,
    suggestions: allSuggestions,
    activeSuggestion,
    checkLimited,
    isUserPremium: userManager.user?.is_premium,
    isLoadingRephraseSuggestions,
    isFirstRephraseSuggestionsRetrieval,
    setActiveSuggestion,
    onContentChangeHandler,
    onTitleChangeHandler,
    onSuggestionSelectHandler,
    onRephraseSuggestionSelectHandler,
    onRephraseSuggestionDiscardHandler,
    onSelectionRephraseHandler,
    getRephraseWritingSuggestions,
    suggestionReplacement,
    createDocument,
    scoresLoading,
    scores,
    overallScoreLoading,
    overallScore,
  }
}

const theme = {
  ltr: 'ltr',
  rtl: 'rtl',
  paragraph: 'editor-paragraph',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    underline: 'editor-text-underline',
  },
  suggestion: 'editor-suggestion',
  rephrase: 'editor-rephrase',
}

export const EditProject: FC = () => {
  const {
    activeSuggestion,
    checkLimited,
    createDocument,
    document,
    documentContentLength,
    documentTitle,
    getRephraseWritingSuggestions,
    isFirstRephraseSuggestionsRetrieval,
    isLoadingRephraseSuggestions,
    isUserPremium,
    onContentChangeHandler,
    onRephraseSuggestionDiscardHandler,
    onRephraseSuggestionSelectHandler,
    onSelectionRephraseHandler,
    onSuggestionSelectHandler,
    onTitleChangeHandler,
    overallScore,
    overallScoreLoading,
    scores,
    scoresLoading,
    setActiveSuggestion,
    suggestionReplacement,
    suggestions,
  } = useEditProject()

  const { projectUuid } = useParams()
  const [showTreeView, setShowTreeView] = useState(false)

  const itemsRef = useRef<Map<number, HTMLElement>>()
  const [
    isAdvancedSuggestionsInfoAcknowledged,
    setIsAdvancedSuggestionsInfoAcknowledged,
  ] = useState(false)

  useEffect(() => {
    if (activeSuggestion === '') return
    const map = getMap()
    const suggestionIndex =
      suggestions?.findIndex(suggestion => {
        return suggestion.id === activeSuggestion
      }) ?? 0
    const node = map.get(suggestionIndex)
    node?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    })
  }, [activeSuggestion])

  const refreshAcknowdledgement = useCallback(() => {
    if (projectUuid) {
      const isAcknowledged = sessionStorage.getItem(`ack[${projectUuid}]`)
      setIsAdvancedSuggestionsInfoAcknowledged(Boolean(isAcknowledged))
    }
  }, [projectUuid])

  useEffect(() => {
    refreshAcknowdledgement()
  })

  function getMap(): Map<number, HTMLElement> {
    if (!itemsRef.current) {
      itemsRef.current = new Map<number, HTMLElement>()
    }
    return itemsRef.current
  }

  const treeViewToggleHandler = () => {
    setShowTreeView(!showTreeView)
  }

  const initialValue = document?.content_formats

  const initialConfig: InitialConfigType = {
    namespace: 'MyEditor',
    editorState: (JSON.stringify(initialValue) !== '{}'
      ? JSON.stringify(initialValue)
      : EMPTY_CONTENT) as InitialEditorStateType,
    theme,
    onError: (error: Error) => {
      captureException(error)
    },
    nodes: [SuggestionNode, RephrasingNode],
  }

  const suggestionPanelProps = {
    activeSuggestion,
    checkLimited,
    createDocument,
    documentContentLength,
    getMap,
    getRephraseWritingSuggestions,
    isAdvancedSuggestionsInfoAcknowledged,
    isFirstRephraseSuggestionsRetrieval,
    isLoadingRephraseSuggestions,
    isUserPremium,
    onRephraseSuggestionDiscardHandler,
    onRephraseSuggestionSelectHandler,
    onSuggestionSelectHandler,
    overallScore,
    overallScoreLoading,
    scores,
    scoresLoading,
    setActiveSuggestion,
    suggestions,
  }

  if (!document) return <PrivatePage />

  return (
    <PrivatePage>
      <div className="flex flex-col px-10 pb-10 gap-6 min-h-[calc(100vh-32px)] h-[calc(100vh-32px)] box-border">
        <div className="flex gap-6 h-full">
          <div className="flex flex-col w-9/12 h-full bg-white rounded-lg">
            <LexicalComposer initialConfig={initialConfig}>
              <div className="px-6 py-4 flex justify-between border-b border-neutral-200">
                <TitleBar
                  documentTitle={documentTitle}
                  onChange={onTitleChangeHandler}
                />
                <ToolbarPlugin
                  onToggleTreeView={treeViewToggleHandler}
                  showTreeView={showTreeView}
                />
              </div>
              <RichTextEditor
                activeSuggestion={activeSuggestion}
                matches={suggestions ?? []}
                showTreeView={showTreeView}
                onChange={onContentChangeHandler}
                onRephraseSuggestionClick={onSelectionRephraseHandler}
                setActiveSuggestion={setActiveSuggestion}
                suggestionReplacement={suggestionReplacement}
              />
            </LexicalComposer>
          </div>
          <div className="flex flex-col w-3/12 h-full gap-4 min-w-[376px]">
            <SuggestionPannel {...suggestionPanelProps} />
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}
