import React, { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import type { IconName } from '../Icon/Icon'
import { Icon } from '../Icon/Icon'

interface ToggleProps {
  active: boolean
  icon: IconName
  disabled?: boolean
  rounded?: boolean
  onClick?: () => void
  className?: string
}

export const Toggle: React.FC<ToggleProps> = ({
  active: initialActive,
  icon,
  disabled,
  rounded = false,
  onClick = () => null,
  className = '',
}) => {
  const [active, setActive] = useState(initialActive)

  useEffect(() => {
    setActive(initialActive)
  }, [initialActive])

  const onClickHandler = () => {
    setActive(!active)
    onClick()
  }

  const cn = clsx(
    'flex items-center justify-center transition-all duration-400 ease-in-out w-6 h-6 hover:bg-neutral-100',
    {
      'bg-neutral-200 hover:bg-neutral-300': active && !disabled,
      'bg-neutral-100 hover:none cursor-not-allowed text-neutral-300': disabled,
      rounded,
    },
    className,
  )

  return (
    <button
      className={cn}
      disabled={disabled}
      onClick={onClickHandler}
      type="button"
    >
      <Icon icon={icon} size="sm" />
    </button>
  )
}
