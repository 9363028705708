import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastType } from 'ui'
import { useTranslation } from 'react-i18next'
import { useCorrectoApi, useToast } from '../../../contexts'

export const AccountActivationCode = () => {
  const navigate = useNavigate()
  const correctoApi = useCorrectoApi()
  const toastManager = useToast()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('activation_code')) {
      correctoApi
        .confirmAccount(searchParams.get('activation_code') ?? '')
        .then(response => {
          if (response.ok) {
            navigate('/login')
          } else {
            toastManager.showToast(
              ToastType.Danger,
              t('Error al activar la cuenta. Por favor, intenta de nuevo.'),
            )
          }
        })
        .catch((error: unknown) => {
          throw error
        })
    }
  }, [])

  return null
}
