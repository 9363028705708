import type { LexicalNode, RangeSelection, TextNode } from 'lexical'
import { $isElementNode, $isTextNode } from 'lexical'
import type { SerializedSuggestionNode } from './SuggestionNode'
import {
  $createSuggestionNode,
  $isSuggestionNode,
  SuggestionNode,
} from './SuggestionNode'

export function $unwrapSuggestionNode(node: SuggestionNode): void {
  const children = node.getChildren()
  let target = null
  for (let i = 0; i < children.length; i++) {
    const child = children[i]
    if (target === null) {
      node.insertBefore(child)
    } else {
      target.insertAfter(child)
    }
    target = child
  }
  node.remove()
}

export function $wrapSelectionInSuggestionNode(
  selection: RangeSelection,
  isBackward: boolean,
  id: string,
  issueType: string,
  createNode?: (ids: string[]) => SuggestionNode,
): void {
  const nodes = selection.getNodes()
  const anchorOffset = selection.anchor.offset
  const focusOffset = selection.focus.offset
  const nodesLength = nodes.length
  const startOffset = isBackward ? focusOffset : anchorOffset
  const endOffset = isBackward ? anchorOffset : focusOffset
  let currentNodeParent
  let lastCreatedSuggestionNode

  // We only want wrap adjacent text nodes, line break nodes
  // and inline element nodes. For decorator nodes and block
  // element nodes, we step out of their boundary and start
  // again after, if there are more nodes.
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i]
    if (
      $isElementNode(lastCreatedSuggestionNode) &&
      lastCreatedSuggestionNode.isParentOf(node)
    ) {
      // If the current node is a child of the last created suggestion node, there is nothing to do here
      continue
    }
    const isFirstNode = i === 0
    const isLastNode = i === nodesLength - 1
    let targetNode: LexicalNode | null = null

    if ($isTextNode(node)) {
      // Case 1: The node is a text node and we can split it
      const textContentSize = node.getTextContentSize()
      const startTextOffset = isFirstNode ? startOffset : 0
      const endTextOffset = isLastNode ? endOffset : textContentSize
      if (startTextOffset === 0 && endTextOffset === 0) {
        continue
      }
      const splitNodes = node.splitText(startTextOffset, endTextOffset)
      targetNode =
        splitNodes.length > 1 &&
        (splitNodes.length === 3 ||
          (isFirstNode && !isLastNode) ||
          endTextOffset === textContentSize)
          ? splitNodes[1]
          : splitNodes[0]
    } else if ($isSuggestionNode(node)) {
      // Case 2: the node is a suggestion node and we can ignore it as a target,
      // moving on to its children. Note that when we make a suggestion inside
      // another suggestion, it may utlimately be unnested by a call to
      // `registerNestedElementResolver<SuggestionNode>` somewhere else in the
      // codebase.

      continue
    } else if ($isElementNode(node) && node.isInline()) {
      // Case 3: inline element nodes can be added in their entirety to the new
      // suggestion
      targetNode = node
    }

    if (targetNode !== null) {
      // Now that we have a target node for wrapping with a suggestion, we can run
      // through special cases.
      if (targetNode && targetNode.is(currentNodeParent)) {
        // The current node is a child of the target node to be wrapped, there
        // is nothing to do here.
        continue
      }
      const parentNode = targetNode.getParent()
      if (parentNode === null || !parentNode.is(currentNodeParent)) {
        // If the parent node is not the current node's parent node, we can
        // clear the last created suggestion node.
        lastCreatedSuggestionNode = undefined
      }

      currentNodeParent = parentNode

      if (lastCreatedSuggestionNode === undefined) {
        // If we don't have a created suggestion node, we can make one
        const createSuggestionNode = createNode ?? $createSuggestionNode
        lastCreatedSuggestionNode = createSuggestionNode([id], issueType)
        targetNode.insertBefore(lastCreatedSuggestionNode)
      }

      // Add the target node to be wrapped in the latest created suggestion node
      lastCreatedSuggestionNode.append(targetNode)
    } else {
      // If we don't have a target node to wrap we can clear our state and
      // continue on with the next node
      currentNodeParent = undefined
      lastCreatedSuggestionNode = undefined
    }
  }
  // Make selection collapsed at the end
  if ($isElementNode(lastCreatedSuggestionNode)) {
    isBackward
      ? lastCreatedSuggestionNode.selectStart()
      : lastCreatedSuggestionNode.selectEnd()
  }
}

export function $getSuggestionIDs(
  node: TextNode,
  offset: number,
): null | string[] {
  let currentNode: LexicalNode | null = node
  while (currentNode !== null) {
    if ($isSuggestionNode(currentNode)) {
      return currentNode.getIDs()
    } else if (
      $isTextNode(currentNode) &&
      offset === currentNode.getTextContentSize()
    ) {
      const nextSibling = currentNode.getNextSibling()
      if ($isSuggestionNode(nextSibling)) {
        return nextSibling.getIDs()
      }
    }
    currentNode = currentNode.getParent()
  }
  return null
}

export {
  $createSuggestionNode,
  $isSuggestionNode,
  SuggestionNode,
  type SerializedSuggestionNode,
}
