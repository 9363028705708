import type { FC, PropsWithChildren, ReactNode } from 'react'
import { CorrectoLogo } from '../CorrectoLogo/CorrectoLogo'
import { Icon } from '../Icon/Icon'
import { MenuItem } from '../MenuItem/MenuItem'

type SidebarProps = PropsWithChildren<{
  isPremium?: boolean
  logoutHandler: () => void
  logoutText?: string
  LanguageSwitcher?: () => ReactNode
}>

export const Sidebar: FC<SidebarProps> = ({
  children,
  logoutHandler,
  logoutText = 'Logout',
  isPremium = false,
  LanguageSwitcher,
}) => {
  return (
    <div className="sidebar h-full overflow-x-scroll w-[300px] min-w-[300px] bg-gray-100">
      <div className="flex flex-col h-full px-6 py-8">
        <div className="box-content flex items-center justify-between">
          <div className="flex justify-between">
            <CorrectoLogo variant="long-blue" />
            {isPremium ? (
              <div className="text-orange-generative-500 rounded-2xl bg-white items-center justify-center ml-2 p-1 w-8 h-8">
                <Icon icon="crown" size="md" />
              </div>
            ) : null}
          </div>
          {LanguageSwitcher ? <LanguageSwitcher /> : null}
        </div>
        <div className="flex flex-col gap-2 pt-10 pb-8">{children}</div>
        <div className="mt-auto">
          <MenuItem
            active={false}
            icon={<Icon icon="logout" size="md" />}
            onClick={logoutHandler}
            title={logoutText}
          />
        </div>
      </div>
    </div>
  )
}
