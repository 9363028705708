import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical'
import { mergeRegister } from '@lexical/utils'
import { Toggle } from 'ui'
import { clsx } from 'clsx'

const LowPriority = 1

const IS_DEV = import.meta.env.DEV

export default function ToolbarPlugin({
  showTreeView = false,
  onToggleTreeView,
}: { showTreeView?: boolean; onToggleTreeView?: () => void } = {}) {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)

  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)

  const treeViewToggleClassName = clsx('self-right', {
    'text-red-extension-500': showTreeView,
    'text-green-checker-500': !showTreeView,
  })

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
    }
  }, [])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          updateToolbar()
          return false
        },
        LowPriority,
      ),
    )
  }, [editor, updateToolbar])

  return (
    <div className="flex justify-between" ref={toolbarRef}>
      <div className="flex">
        <Toggle
          active={isBold}
          className="text-neutral-600"
          icon="bold"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
        />
        <Toggle
          active={isItalic}
          className="text-neutral-600"
          icon="italic"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
        />
        <Toggle
          active={isUnderline}
          className="text-neutral-600"
          icon="underlined"
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
        />
      </div>
      {IS_DEV ? (
        <Toggle
          active={showTreeView}
          className={treeViewToggleClassName}
          icon="bulb"
          onClick={() => {
            if (onToggleTreeView) {
              onToggleTreeView()
            }
          }}
        />
      ) : null}
    </div>
  )
}
