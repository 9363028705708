import * as amplitude from '@amplitude/analytics-browser'
import { Experiment } from '@amplitude/experiment-js-client'
import type { ExperimentClient } from '@amplitude/experiment-js-client'

const AMPLITUDE_KEY = import.meta.env.VITE_AMPLITUDE_KEY
let experimentClient: ExperimentClient | null = null

export const configAmplitude = () => {
  if (!AMPLITUDE_KEY) return

  amplitude.init(AMPLITUDE_KEY, {
    defaultTracking: {
      attribution: false,
      pageViews: true,
      sessions: false,
      formInteractions: false,
      fileDownloads: false,
    },
  })
}

export const initializeExperiment = async () => {
  if (!AMPLITUDE_KEY) return

  experimentClient = Experiment.initializeWithAmplitudeAnalytics(AMPLITUDE_KEY)
  await experimentClient.start()
}

export const belongsExtensionCohort = async () => {
  if (!experimentClient) {
    await initializeExperiment()
  }

  return experimentClient?.variant('has-chrome-installed')?.value === 'on'
}

type AmplitudeActionName =
  | 'Select price'
  | 'Change price interval'
  | 'Get new advanced suggestion'
  | 'Get new advanced suggestion for selection'
  | 'Rephrase info I want to know more'
  | 'Rephrase info close'
  | 'Rephrase I want to upgrade to premium'
  | 'Sign in Google'
  | 'Sign up Google'
  | 'Sign up Google Chrome Extension'
  | 'Sign in Email'
  | 'Sign up Email'
  | 'Sign up Email Chrome Extension'
  | 'Download Chrome - Banner'
  | 'Download Chrome - Modal WebApp'

interface AmplitudeAction {
  name: AmplitudeActionName
  extra?: Record<string, string | number | undefined>
}

export const trackUserAction = (payload: AmplitudeAction | MetricsPayload) => {
  amplitude.track('User Action', payload)
}

// New implementation:
export enum MetricsCategory {
  SuggestionInteracted = 'Suggestion Interacted',
  FeatureInteracted = 'Feature Interacted',
  AiRequested = 'AI Requested',
  AuthInteracted = 'Auth Interacted',
  ScreenViewed = 'Screen Viewed',
  GrowthInteracted = 'Growth Interacted',
  ChromeInteracted = 'Chrome Interacted',
  SurveyInteracted = 'Survey Interacted',
}

export enum MetricsName {
  GrammarAccepted = 'Grammar Accepted',
  RephraseAccepted = 'Rephrase Accepted',
  PunctuationAccepted = 'Punctuation Accepted',
  GrammarDismiss = 'Grammar Dismiss',
  RephraseDismiss = 'Rephrase Dismiss',
  PunctuationDismiss = 'Punctuation Dismiss',
  ReportOpened = 'Report Opened',
  EmailSignedUp = 'Email Signed Up',
  GoogleSignedUp = 'Google Signed Up',
  EmailLoggedIn = 'Email Logged In',
  GoogleLoggedIn = 'Google Logged In',
  PremiumClicked = 'Premium Clicked',
  GmailClicked = 'Gmail Clicked',
  WhatsappClicked = 'Whatsapp Clicked',
  WebappClicked = 'Webapp Clicked',
  OnboardingViewed = 'Onboarding Viewed',
}

export interface MetricsPayload {
  name: string
  location?: string
  place?: Place
  source?: Source
  step?: number
}

type Source = 'Chrome extension' | 'Webapp'

export type Place =
  | 'Contextual card'
  | 'Hover card'
  | 'Content modal'
  | 'Sidebar'

export const trackEvent = (
  eventType: MetricsCategory,
  eventProperties: MetricsPayload,
) => {
  amplitude.track(eventType, { source: 'Webapp', ...eventProperties })
}
