import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'ui'

interface Feature {
  icon: string
  text: string
  subtext?: string
}

const PlanCard: React.FC<{
  title: string
  price: string
  interval: string
  yearlyPrice: string
  features: Feature[]
  isPremium: boolean
  onButtonClick?: () => Promise<void>
}> = ({
  title,
  price,
  interval,
  yearlyPrice,
  features,
  isPremium,
  onButtonClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className={isPremium ? 'border-gradient' : ''}>
      <div
        style={{
          boxShadow: '0px 4px 8px 0px rgba(12, 26, 102, 0.40)',
        }}
        className={`p-6 w-[418px] ${isPremium ? 'border-gradient-content' : 'mt-[40px] rounded-3xl'} px-14 py-8`}
      >
        <div className="flex justify-between items-center flex-col">
          <h2
            className={`text-4xl font-bold ${isPremium ? 'text-neutral-800' : 'text-neutral-600'} mb-4 flex items-center gap-2`}
          >
            {isPremium ? <span>👑</span> : null}
            <span>{title}</span>
          </h2>
          <p
            className={`text-4xl font-bold ${isPremium ? 'text-blue-correcto-700' : 'text-neutral-500'}`}
          >
            {price}
          </p>
          <p className="text-lg text-neutral-950">{interval}</p>
          <p
            className={`text-sm ${isPremium ? 'text-neutral-900' : 'text-gray-500'}`}
          >
            {yearlyPrice}
          </p>
          <button
            onClick={() => {
              if (onButtonClick) onButtonClick()
            }}
            className={`w-[306px] mt-4 mb-8 py-3 px-4 rounded-full ${
              isPremium
                ? 'bg-blue-correcto-500 text-white font-bold hover:bg-blue-600'
                : 'bg-gray-300 text-neutral-500 font-semibold cursor-not-allowed'
            }`}
          >
            {isPremium ? (
              <span className="flex items-center gap-2 justify-center">
                <Icon icon="crown" size="md" />
                {t('Hazte prémium')}
              </span>
            ) : (
              <>{t('Plan actual')}</>
            )}
          </button>
        </div>
        <ul
          className={`flex flex-col gap-2 mt-4 space-y-2 ${isPremium ? 'text-neutral-800' : 'text-neutral-600'} text-md font-medium`}
        >
          {features.map((feature, index) => (
            <li key={index} className="flex gap-4 items-center">
              <div>
                <Icon icon={feature.icon} size="md" />
              </div>
              <div>
                <p>{feature.text}</p>
                {feature.subtext ? (
                  <p className={isPremium ? 'font-bold' : ''}>
                    {feature.subtext}
                  </p>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PlanCard
