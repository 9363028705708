import { clsx } from 'clsx'
import { useMemo, type FC } from 'react'
import { Typography } from '../Typography/Typography'

interface RangeProps {
  className?: string
  labels?: string[]
  value: number
}

export const Range: FC<RangeProps> = ({ className, labels, value }) => {
  const containerClassName = clsx(className, 'w-full')
  const width = useMemo(() => {
    return `${value.toString()}%`
  }, [value])

  return (
    <div className={containerClassName}>
      <div className="h-1.5 bg-neutral-300 rounded-lg">
        <div
          className="h-full bg-gradient-secondary rounded-lg"
          style={{ width }}
        />
      </div>
      {labels ? (
        <div className="flex justify-between w-full">
          {labels.map(label => (
            <Typography
              className="text-neutral-600"
              key={`range-label-${label}`}
              variant="small"
            >
              {label}
            </Typography>
          ))}
        </div>
      ) : null}
    </div>
  )
}
