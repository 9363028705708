import type { FC } from 'react'
import { Icon } from 'ui'
import { useTranslation } from 'react-i18next'
import { MIN_CHARACTERS_REPHRASING } from '../../../../utils/constants'
import { useUser } from '../../../../contexts'
import {
  ScoringPreviewPanel,
  type ScoringPreviewPanelProps,
} from '../ScoringPreviewPanel/ScoringPreviewPanel'
import { GetMoreRephraseSuggestionsButton } from '../GetMoreRephraseSuggestionsButton/GetMoreRephraseSuggestionsButton'
import { trackUserAction } from '../../../../utils/amplitude'

interface ComponentProps extends ScoringPreviewPanelProps {
  suggestionCount: number
  documentLength: number
  getRephraseWritingSuggestions: (params: { selected_text?: string }) => void
  showGetMoreRephraseSuggestionsButton: boolean
}

export const SuggestionPannelHeader: FC<ComponentProps> = ({
  documentLength,
  getRephraseWritingSuggestions,
  overallScore,
  overallScoreLoading,
  scores,
  scoresLoading,
  showGetMoreRephraseSuggestionsButton,
  suggestionCount,
}) => {
  const userManager = useUser()
  const { t } = useTranslation()

  const isUserPremium = userManager.user?.is_premium

  const showAdvancedSuggestionsTitle =
    !isUserPremium &&
    !suggestionCount && // Esto hace que nunca se muestre el título de sugerencias avanzadas? Use cases?
    documentLength > MIN_CHARACTERS_REPHRASING

  const suggestionCountText =
    suggestionCount > 0 ? suggestionCount.toString() : ''

  return (
    <div className="sticky top-0 bg-white shadow-[0_4px_4px_0_rgba(0,0,0,0.2)] border border-neutral-200 rounded-[8px_8px_0_0]">
      <div className="h-15 p-4 flex justify-between ">
        {showAdvancedSuggestionsTitle ? (
          <div className="text-blue-correcto-800 font-bold flex items-center text-small">
            <div className="pr-2">
              <Icon icon="target" size="md" />
            </div>
            {t('Sugerencias avanzadas')}
          </div>
        ) : (
          <p className="text-blue-correcto-800 font-bold flex items-center text-small">
            {t('Sugerencias')}{' '}
            <span className="rounded-full bg-red-extension-500 min-w-[18px] h-[18px] ml-2 flex justify-center items-center font-medium text-white px-1.5 text-[10px]">
              {suggestionCountText}
            </span>
          </p>
        )}
        <ScoringPreviewPanel
          scores={scores}
          overallScore={overallScore}
          overallScoreLoading={overallScoreLoading}
          scoresLoading={scoresLoading}
        />
      </div>
      {showGetMoreRephraseSuggestionsButton ? (
        <GetMoreRephraseSuggestionsButton
          onClick={() => {
            getRephraseWritingSuggestions({})
            trackUserAction({
              name: 'Get new advanced suggestion',
            })
          }}
        />
      ) : null}
    </div>
  )
}
