import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { OauthClient } from '../clients/OauthClient'

type OauthContextType = OauthClient

const OauthContext = createContext<OauthContextType | null>(null)

export const OauthProvider: FC<PropsWithChildren> = ({ children }) => {
  const api = new OauthClient()

  return <OauthContext.Provider value={api}>{children}</OauthContext.Provider>
}

export const useOauth = () => {
  return useContext(OauthContext)!
}
