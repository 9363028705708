import type { FC, ReactNode } from 'react'
import { clsx } from 'clsx'

interface TypographyProps {
  variant: 'h1' | 'h2' | 'h3' | 'subtitle' | 'body1' | 'body2' | 'small'
  children: ReactNode
  component?: React.ElementType
  className?: string
  htmlFor?: string
}

export const Typography: FC<TypographyProps> = ({
  component: Component = 'span',
  variant,
  children,
  className = '',
  htmlFor,
}) => {
  const typographyClassName = clsx(`text-${variant}`, className)

  return (
    <Component className={typographyClassName} htmlFor={htmlFor}>
      {children}
    </Component>
  )
}
