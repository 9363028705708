import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ToastType } from 'ui'
import type { StripePrice, StripeProduct } from '../clients/CorrectoApiClient'
import { PrivatePage } from '../Components'
import { useCorrectoApi, useToast, useUser } from '../contexts'
import { MetricsCategory, MetricsName, trackEvent } from '../utils/amplitude'
import PlanCard from './PlanCard'

type PriceInterval = 'month' | 'year'

export const Premium: React.FC = () => {
  const [priceInterval, setPriceInterval] = useState<PriceInterval>('year')
  const [products, setProducts] = useState<StripeProduct[]>([])
  const [price, setPrice] = useState<StripePrice>()
  const { t } = useTranslation()
  const toastManager = useToast()
  const correctoApi = useCorrectoApi()
  const userManager = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (userManager.user?.is_premium) {
      navigate('/projects')
    }
  }, [userManager.user, navigate])

  useEffect(() => {
    ;(async () => {
      const response = await correctoApi.getProducts()

      if (response.ok) {
        setProducts(response.body.results)
        setPrice(response.body.results[0].default_price)
      } else {
        toastManager.showToast(
          ToastType.Danger,
          t('Se ha producido un error al obtener los productos'),
        )
      }
    })()
  }, [])

  const handlePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const interval = e.currentTarget.value as PriceInterval
    setPriceInterval(interval)
    setPrice(
      products[0]?.prices.find(
        p =>
          p.recurring.interval === interval &&
          p.currency === products[0].default_price.currency,
      ),
    )
  }

  const handleCheckout = async () => {
    if (!price) return

    trackEvent(MetricsCategory.GrowthInteracted, {
      name: MetricsName.PremiumClicked,
      location: 'Premium Page',
    })

    const response = await correctoApi.checkout(price.id)

    if (response.ok) {
      window.location.href = response.body.checkout_url
    } else {
      toastManager.showToast(
        ToastType.Danger,
        t('Se ha producido un error al redirectionar a la pasarela de pago'),
      )
      throw new Error(JSON.stringify(response))
    }
  }

  if (!price || !products || products.length === 0) return <PrivatePage />

  const currency = products[0].default_price.currency
  const priceFormat = currency.toLowerCase() === 'usd' ? 'en-US' : 'es-ES'
  const productPrice = price.unit_amount / 100
  const monthlyPrice =
    priceInterval === 'month' ? productPrice : productPrice / 12
  const annualPrice =
    priceInterval === 'year' ? productPrice : productPrice * 12
  const formatPrice = (amount: number) =>
    new Intl.NumberFormat(priceFormat, { style: 'currency', currency }).format(
      amount,
    )
  const monthlyPriceFormatted = formatPrice(monthlyPrice)
  const annualPriceFormatted = formatPrice(annualPrice)

  const freeFeatures = [
    { icon: 'circle-check', text: t('Corrección ortográfica y gramatical') },
    {
      icon: 'aiServices',
      text: t('Sugerencias de parafraseo'),
      subtext: t('(30 usos)'),
    },
    {
      icon: 'synomyms',
      text: t('Sugerencias de puntuación'),
      subtext: t('(30 usos)'),
    },
  ]

  const premiumFeatures = [
    {
      icon: 'circle-check',
      text: t('Corrección ortográfica y gramatical avanzada'),
    },
    {
      icon: 'aiServices',
      text: t('Sugerencias de parafraseo'),
      subtext: t('(ilimitadas)'),
    },
    {
      icon: 'synomyms',
      text: t('Sugerencias de sinónimos'),
    },
    {
      icon: 'punctuationMark',
      text: t('Signos de puntuación'),
      subtext: t('(ilimitadas)'),
    },
    { icon: 'bookClose', text: t('Diccionario') },
  ]

  return (
    <PrivatePage>
      <div className="mb-4">
        <h1 className="text-3xl font-bold mb-6 ml-8 text-neutral-800">
          {t('Escribe sin límites con el Plan Prémium')}
        </h1>
        <div className="flex ml-8 space-x-6">
          <PlanCard
            title={t('Free')}
            price={currency === 'usd' ? '$0.00' : '0,00€'}
            interval={t('al mes')}
            yearlyPrice={`${currency === 'usd' ? '$0.00' : '0,00€'} ${t('al año')}`}
            features={freeFeatures}
            isPremium={false}
          />
          <div className="flex flex-col items-center justify-start">
            <div className="flex justify-center mb-6 text-neutral-600 text-xs">
              {['month', 'year'].map(interval => (
                <label
                  key={interval}
                  className="inline-flex items-center space-x-2 ml-6 first:ml-0"
                >
                  <input
                    type="radio"
                    value={interval}
                    checked={priceInterval === interval}
                    onChange={handlePrice}
                    className="form-radio text-purple-ia-500 cursor-pointer"
                  />
                  <span>
                    {interval === 'month' ? t('Mensual') : t('Anual')}
                  </span>
                </label>
              ))}
            </div>
            <PlanCard
              title={t('Prémium')}
              price={monthlyPriceFormatted}
              interval={t('al mes')}
              yearlyPrice={`${annualPriceFormatted} ${t('al año')}`}
              features={premiumFeatures}
              isPremium
              onButtonClick={handleCheckout}
            />
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}
