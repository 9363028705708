import type { FC } from 'react'
import { clsx } from 'clsx'
import { Icon, type IconName } from '../Icon/Icon'

export type IconButtonVariant = 'primary' | 'neutral'

interface IconButtonProps {
  icon: IconName
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  variant?: IconButtonVariant
  className?: string
}

export const IconButton: FC<IconButtonProps> = ({
  variant = 'primary',
  icon,
  onClick,
  className,
}) => {
  const iconbuttonClassName = clsx(className, {
    'p-1 rounded-lg hover:bg-neutral-200 active:bg-neutral-400 focus:outline-none':
      true,
    'text-blue-correcto-500': variant === 'primary',
    'text-neutral-500': variant === 'neutral',
  })
  return (
    <button className={iconbuttonClassName} onClick={onClick} type="button">
      <Icon icon={icon} size="sm" />
    </button>
  )
}
