import type { TextNode, ParagraphNode } from 'lexical'
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isParagraphNode,
  $isTextNode,
  $createTextNode,
  $isNodeSelection,
} from 'lexical'
import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  SuggestionType,
  type Match,
} from '../../../../../../clients/CorrectoApiClient'
import { SuggestionNode } from '../../Nodes'
import {
  $isSuggestionNode,
  $createSuggestionNode,
  $unwrapSuggestionNode,
} from '../../Nodes/SuggestionNode'
import { captureException } from '../../../../../../utils/sentry'
import { ErrorColoursWithOpacity } from '../../Nodes/SuggestionNode/utils'

interface SuggestionPluginProps {
  suggestions: Match[]
  activeSuggestion: string
  suggestionReplacement?: { suggestion: string; id: string }
}

export function SuggestionPlugin({
  suggestions,
  activeSuggestion,
  suggestionReplacement,
}: SuggestionPluginProps) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    document
      .querySelectorAll<HTMLElement>('.editor-suggestion.active')
      .forEach(el => {
        el.classList.remove('active')
        el.style.background = 'none'
      })
    const suggestion = document.querySelectorAll<HTMLElement>(
      `.editor-suggestion[data-suggestion="${activeSuggestion}"]`,
    )[0]

    if (!suggestion) return

    suggestion.classList.add('active')
    const value = suggestions.findIndex(el => el.id === activeSuggestion)
    const backgroundColorWithOpacity =
      ErrorColoursWithOpacity[suggestions[value].rule?.issueType] ||
      ErrorColoursWithOpacity.other
    suggestion.style.backgroundColor = backgroundColorWithOpacity
  }, [activeSuggestion])

  useEffect(() => {
    if (!suggestionReplacement) return
    editor.update(
      () => {
        const root = $getRoot()

        root.getAllTextNodes().forEach((textNode: TextNode) => {
          if ($isSuggestionNode(textNode.getParent())) {
            const suggestionNode: SuggestionNode = textNode.getParent()!
            if (suggestionNode.__ids.includes(suggestionReplacement.id)) {
              textNode.setTextContent(suggestionReplacement.suggestion)
              $unwrapSuggestionNode(textNode.getParent()!)
            }
          }
        })
      },
      { tag: 'history-merge' },
    )
  }, [suggestionReplacement, editor])

  useEffect(() => {
    editor.update(
      () => {
        const root = $getRoot()
        const paragraphNodes = root.getChildren()

        const suggestionNodeIds = root
          .getAllTextNodes()
          .filter((textNode: TextNode) =>
            $isSuggestionNode(textNode.getParent()),
          )
          .map(
            (textNode: TextNode) =>
              textNode.getParent()! as SuggestionNode | null,
          )
          .map((suggestionNode: SuggestionNode | null) => suggestionNode?.__ids)
          .flat()

        const suggestionIds = suggestions.map(
          (rephrasing: Match) => rephrasing.id,
        )

        root
          .getAllTextNodes()
          .filter((textNode: TextNode) =>
            $isSuggestionNode(textNode.getParent()),
          )
          .map(
            (textNode: TextNode) =>
              textNode.getParent()! as SuggestionNode | null,
          )
          .forEach((suggestionNode: SuggestionNode | null) => {
            if (
              suggestionNode?.__ids[0] &&
              !suggestionIds.includes(suggestionNode?.__ids[0])
            ) {
              $unwrapSuggestionNode(suggestionNode)
            }
          })

        if (suggestions.length === 0) return

        paragraphNodes.forEach(paragraphNode => {
          if (!$isParagraphNode(paragraphNode)) return

          const paragraphOffset = root
            .getTextContent()
            .replace(/\n+/g, '\n')
            .indexOf(paragraphNode.getTextContent())

          const paragraphLength = paragraphNode.getTextContentSize()

          const filteredSuggestions = suggestions
            .filter(
              match =>
                match.type.typeName !== SuggestionType.RephraseSuggestion &&
                !suggestionNodeIds.includes(match.id),
            )
            .filter(
              suggestion =>
                suggestion.offset >= paragraphOffset &&
                suggestion.offset <= paragraphOffset + paragraphLength,
            )

          handleLinkCreation(
            paragraphNode,
            filteredSuggestions,
            activeSuggestion,
            paragraphOffset,
          )
        })
      },
      { tag: 'history-merge' },
    )
  }, [suggestions, editor])

  if (!editor.hasNodes([SuggestionNode])) {
    throw new Error(
      'SuggestionsPlugin requires SuggestionsNode to be registered',
    )
  }

  return null
}

function handleLinkCreation(
  paragraphNode: ParagraphNode,
  matchers: Match[],
  activeId: string,
  prevLength = 0,
): void {
  let currentNodes = paragraphNode.getAllTextNodes()
  const entireText = paragraphNode.getTextContent().replace(/\n+/g, '\n')
  let text = entireText

  let carret = prevLength
  matchers.forEach(match => {
    const matchStart = match.offset - carret
    const matchLength = match.length
    const matchEnd = matchStart + matchLength
    const replacedText = text.substring(matchStart, matchEnd)

    carret += matchStart + matchLength

    const [matchingOffset, , matchingNodes, unmodifiedAfterNodes] =
      extractMatchingNodes(currentNodes, matchStart, matchEnd)

    const actualMatchStart = matchStart - matchingOffset
    const actualMatchEnd = matchEnd - matchingOffset

    try {
      const remainingTextNode = createSuggestionNode(
        matchingNodes,
        actualMatchStart,
        actualMatchEnd,
        replacedText,
        match.id,
        match.rule?.issueType,
        activeId,
      )
      currentNodes = remainingTextNode
        ? [remainingTextNode, ...unmodifiedAfterNodes]
        : unmodifiedAfterNodes
    } catch (err: unknown) {
      captureException(err)
    }

    text = text.substring(matchEnd)
  })
}

function extractMatchingNodes(
  nodes: TextNode[],
  startIndex: number,
  endIndex: number,
): [
  matchingOffset: number,
  unmodifiedBeforeNodes: TextNode[],
  matchingNodes: TextNode[],
  unmodifiedAfterNodes: TextNode[],
] {
  const unmodifiedBeforeNodes: TextNode[] = []
  const matchingNodes: TextNode[] = []
  const unmodifiedAfterNodes: TextNode[] = []
  let matchingOffset = 0

  let currentOffset = 0
  const currentNodes = [...nodes]

  while (currentNodes.length > 0) {
    const currentNode = currentNodes[0]
    const currentNodeText = currentNode.getTextContent()
    const currentNodeLength = currentNodeText.length
    const currentNodeStart = currentOffset
    const currentNodeEnd = currentOffset + currentNodeLength

    if (currentNodeEnd <= startIndex) {
      unmodifiedBeforeNodes.push(currentNode)
      matchingOffset += currentNodeLength
    } else if (currentNodeStart >= endIndex) {
      unmodifiedAfterNodes.push(currentNode)
    } else {
      matchingNodes.push(currentNode)
    }
    currentOffset += currentNodeLength
    currentNodes.shift()
  }
  return [
    matchingOffset,
    unmodifiedBeforeNodes,
    matchingNodes,
    unmodifiedAfterNodes,
  ]
}

function createSuggestionNode(
  nodes: TextNode[],
  startIndex: number,
  endIndex: number,
  text: string,
  id: string,
  issueType: string,
  activeId: string,
): TextNode | undefined {
  const linkNode = $createSuggestionNode([id], issueType, activeId)
  if (nodes.length === 1) {
    let remainingTextNode = nodes[0]
    let linkTextNode
    if (startIndex === 0) {
      ;[linkTextNode, remainingTextNode] = remainingTextNode.splitText(endIndex)
    } else {
      ;[, linkTextNode, remainingTextNode] = remainingTextNode.splitText(
        startIndex,
        endIndex,
      )
    }

    const textNode = $createTextNode(text)
    textNode.setFormat(linkTextNode.getFormat())
    textNode.setDetail(linkTextNode.getDetail())
    linkNode.append(textNode)
    linkTextNode.replace(linkNode)
    return remainingTextNode
  } else if (nodes.length > 1) {
    const firstTextNode = nodes[0]
    let offset = firstTextNode.getTextContent().length
    let firstLinkTextNode
    if (startIndex === 0) {
      firstLinkTextNode = firstTextNode
    } else {
      ;[, firstLinkTextNode] = firstTextNode.splitText(startIndex)
    }
    const linkNodes = []
    let remainingTextNode
    for (let i = 1; i < nodes.length; i++) {
      const currentNode = nodes[i]
      const currentNodeText = currentNode.getTextContent()
      const currentNodeLength = currentNodeText.length
      const currentNodeStart = offset
      const currentNodeEnd = offset + currentNodeLength
      if (currentNodeStart < endIndex) {
        if (currentNodeEnd <= endIndex) {
          linkNodes.push(currentNode)
        } else {
          const [linkTextNode, endNode] = currentNode.splitText(
            endIndex - currentNodeStart,
          )
          linkNodes.push(linkTextNode)
          remainingTextNode = endNode
        }
      }
      offset += currentNodeLength
    }
    const selection = $getSelection()
    const selectedTextNode = selection
      ? selection.getNodes().find($isTextNode)
      : undefined
    const textNode = $createTextNode(firstLinkTextNode.getTextContent())
    textNode.setFormat(firstLinkTextNode.getFormat())
    textNode.setDetail(firstLinkTextNode.getDetail())
    linkNode.append(textNode, ...linkNodes)
    // it does not preserve caret position if caret was at the first text node
    // so we need to restore caret position
    if (selectedTextNode && selectedTextNode === firstLinkTextNode) {
      if ($isRangeSelection(selection)) {
        textNode.select(selection.anchor.offset, selection.focus.offset)
      } else if ($isNodeSelection(selection)) {
        textNode.select(0, textNode.getTextContent().length)
      }
    }
    firstLinkTextNode.replace(linkNode)
    return remainingTextNode
  }
  return undefined
}
