import type { FC, PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useCorrectoApi } from '../../contexts'
import Onboarding from '../../Onboarding/Onboarding'

export const PublicRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const correctoApi = useCorrectoApi()

  if (correctoApi.isAuthenticated()) {
    if (location.pathname === '/onboarding') {
      ;(async () => {
        const user = await correctoApi.getUser()
        correctoApi.sendTokenToExtension(user.body)
      })()

      return <Onboarding />
    }

    const { next }: { next: string } = location.state || {
      next: '/projects',
    }

    return <Navigate to={next} />
  }

  if (location.pathname === '/onboarding') {
    return <Navigate to="/sign-up?extension=true&onboarding=true" />
  }

  return <>{children}</>
}
