import React from 'react'

interface StepIndicatorProps {
  currentStep: number
  totalSteps: number
  onStepClick: (step: number) => void
}

const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentStep,
  totalSteps,
  onStepClick,
}) => (
  <div className="flex space-x-3 mb-8">
    {Array.from({ length: totalSteps }, (_, index) => (
      <button
        key={index}
        className={`w-[10.6px] h-[10.6px] rounded-full cursor-pointer ${
          currentStep === index + 1 ? 'bg-blue-500' : 'bg-gray-300'
        }`}
        tabIndex={0}
        onClick={() => onStepClick(index + 1)}
        onKeyDown={e => {
          if (e.key === 'Enter' || e.key === ' ') {
            onStepClick(index + 1)
          }
        }}
      />
    ))}
  </div>
)

export default StepIndicator
