import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Banner } from 'ui'
import { belongsExtensionCohort } from '../../utils/amplitude'

const ALLOW_ROUTE = ['/projects', '/dictionary', '/settings']
const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000

export const BannerChrome: FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const shouldShowBannerChrome = ALLOW_ROUTE.includes(location.pathname)
  const [hasChromeInstalled, setHasChromeInstalled] = useState(true)
  const alreadyInstalledExtension = localStorage.getItem('extensionId')
  const [bannerClosed, setBannerClosed] = useState(() => {
    const closedInfo = JSON.parse(localStorage.getItem('bannerClosed')!)
    if (closedInfo) {
      const { closedAt, count } = closedInfo
      const now = Date.now()

      if (count >= 2) {
        return true
      }

      if (now - closedAt >= ONE_WEEK_IN_MS) {
        return false
      }

      return true
    }

    return false
  })

  useEffect(() => {
    if (bannerClosed) {
      const closedInfo = JSON.parse(localStorage.getItem('bannerClosed')!) || {}
      const newClosedInfo = {
        closedAt: Date.now(),
        count: closedInfo.count ? closedInfo.count + 1 : 1,
      }
      localStorage.setItem('bannerClosed', JSON.stringify(newClosedInfo))
    }
  }, [bannerClosed])

  useEffect(() => {
    if (alreadyInstalledExtension) {
      setHasChromeInstalled(true)
    } else {
      belongsExtensionCohort().then(result => {
        setHasChromeInstalled(result)
      })
    }
  }, [])

  const handleClickBanner = () => {
    window.open(
      'https://chromewebstore.google.com/detail/correcto-herramienta-de-e/hllekbchnfikjpbdielihahenjmbpach',
      '_blank',
      'noopener',
    )

    setBannerClosed(true)
  }

  const handleCloseBanner = () => {
    setBannerClosed(true)
  }

  return (
    <>
      {!bannerClosed && shouldShowBannerChrome && !hasChromeInstalled ? (
        <div className="box-content flex pb-8 px-10">
          <Banner
            buttonText={t('Añadir extensión gratis')}
            description={t(
              'Descárgala ahora y mejora tus textos en Gmail y en WhatsApp al instante.',
            )}
            ilustration="extension-folder"
            onClick={handleClickBanner}
            onClose={handleCloseBanner}
            title={t('¿Aún no tienes la extensión de Chrome instalada?')}
            hasBgImage
            leftIcon="chromeColor"
          />
        </div>
      ) : null}
    </>
  )
}
