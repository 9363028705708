import { Button, Ilustration, Typography } from 'ui'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PrivatePage } from '../../Components'

export const CheckoutCanceled = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const onPrimaryClick = () => {
    navigate('/premium')
  }

  return (
    <PrivatePage>
      <div className="flex flex-col gap-10 items-center">
        <Typography
          className="font-bold text-blue-correcto-800"
          component="h1"
          variant="subtitle"
        >
          {t('Sácale todo el partido a Correcto')}
        </Typography>
        <div className="flex flex-col gap-12 bg-white, rounded-3xl shadow-1 px-14 py-12 w-[418px]">
          <div className="flex flex-col items-center gap-12">
            <Ilustration ilustration="more-info" />
            <div className="flex flex-col gap-4">
              <Typography
                className="text-blue-correcto-700 text-center font-bold"
                component="h2"
                variant="body1"
              >
                {t('El pago no ha podido efectuarse')}
              </Typography>
              <Typography
                className="text-center font-normal"
                component="p"
                variant="body2"
              >
                {t(
                  'Lamentamos informarte que hemos detectado un inconveniente en el proceso de pago de tu suscripción Premium. Por favor, inténtalo nuevamente y disculpa las molestias.',
                )}
              </Typography>
              <Button
                label={t('Quiero hacerme Premium')}
                leftIcon="crown"
                onClick={onPrimaryClick}
                variant="premium"
              />
            </div>
          </div>
        </div>
      </div>
    </PrivatePage>
  )
}
