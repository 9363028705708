import type { FC, ReactNode } from 'react'
import { Typography } from '../Typography/Typography'
import { IconButton } from '../IconButton/IconButton'

interface CardProps {
  title: string
  children: ReactNode
  onActionClick: () => void
  onClick: () => void
}

export const ProjectCard: FC<CardProps> = ({
  title,
  children,
  onActionClick,
  onClick,
}) => {
  const handleOnActionClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onActionClick()
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-2 w-[342px] h-[132px] border border-white hover:border-neutral-400 relative">
      <button className="contents text-left" onClick={onClick} type="button">
        <div className="flex gap-2 justify-between items-center mb-2">
          <Typography
            className="text-blue-correcto-800 text-ellipsis overflow-hidden whitespace-nowrap font-bold pr-6"
            variant="body1"
          >
            {title}
          </Typography>
        </div>
        <Typography
          className="text-neutral-700 line-clamp-4 font-medium"
          component="p"
          variant="small"
        >
          {children}
        </Typography>
      </button>
      <IconButton
        className="absolute top-4 right-4"
        icon="trash"
        onClick={handleOnActionClick}
      />
    </div>
  )
}
