import type { FC } from 'react'
import { Button, Ilustration, Typography } from 'ui'
import type { IlustrationName } from 'ui/src/Ilustration/Ilustration'

interface EmptyStateProps {
  primaryButtonHandler: () => void
  ilustration: IlustrationName
  content: string
  primaryAction: string
}

export const EmptyState: FC<EmptyStateProps> = ({
  primaryButtonHandler,
  ilustration,
  content,
  primaryAction,
}) => (
  <div className="flex flex-col gap-4 items-center">
    <div className="w-36 h-36">
      <Ilustration ilustration={ilustration} />
    </div>
    <Typography className="font-normal" variant="body2">
      {content}
    </Typography>
    <div className="text-center">
      <Button
        label={primaryAction}
        leftIcon="file-plus"
        onClick={primaryButtonHandler}
        size="small"
      />
    </div>
  </div>
)
