import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { IconButton, Input, Typography, useModal } from 'ui'
import { useTranslation } from 'react-i18next'

interface TitleBarProps {
  documentTitle?: string
  onChange: (title: string) => void
}

export const TitleBar: FC<TitleBarProps> = ({ documentTitle, onChange }) => {
  const { editTitle } = useTitleBar(onChange, documentTitle)

  return (
    <div className="flex h-6 items-center gap-2">
      <Typography
        className="text-blue-correcto-800 font-bold text-ellipsis overflow-hidden whitespace-nowrap uppercase"
        component="h2"
        variant="small"
      >
        {documentTitle}
      </Typography>
      <IconButton icon="pencil" onClick={editTitle} />
    </div>
  )
}

interface ChangeTitleFormProps {
  title: string
  onChange: (s: string) => void
  onEnterPressed: () => void
}

const ChangeTitleForm: FC<ChangeTitleFormProps> = ({
  title,
  onChange,
  onEnterPressed,
}) => {
  const [newTitle, setNewTitle] = useState<string>(title)
  const { t } = useTranslation()
  return (
    <Input
      label={t('Nombre del proyecto')}
      maxLength={100}
      onChange={e => {
        setNewTitle(e.target.value)
        onChange(e.target.value)
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onEnterPressed()
        }
      }}
      requiredMessage={t('El nombre del proyecto es obligatorio')}
      value={newTitle}
    />
  )
}

const useTitleBar = (
  onChange: (title: string) => void,
  documentTitle?: string,
) => {
  const [newTitle, setNewTitle] = useState<string>('')
  const [primaryClicked, setPrimaryClicked] = useState<boolean>(false)
  const { t } = useTranslation()

  const modalManager = useModal()

  useEffect(() => {
    primaryClicked && onChange(newTitle)
    setPrimaryClicked(false)
    modalManager.closeModal()
  }, [primaryClicked])

  const editTitle = () => {
    if (!documentTitle) return

    modalManager.openModal({
      body: (
        <ChangeTitleForm
          onChange={setNewTitle}
          onEnterPressed={() => {
            setPrimaryClicked(true)
          }}
          title={documentTitle}
        />
      ),
      title: t('Cambiar nombre'),
      subtitle: t('Modifica el nombre del proyecto'),
      type: 'interactive',
      primaryAction: t('Guardar'),
      primaryActionType: 'primary',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      titleIcon: 'pencil',
      onPrimaryClick: () => {
        setPrimaryClicked(true)
      },
    })
  }

  return {
    editTitle,
  }
}
