import { useTranslation } from 'react-i18next'
import { Button, Input } from 'ui'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { PublicPage, PageHeader } from '../../Components'
import { useCorrectoApi } from '../../../contexts'
import { useCommonApiErrorMessage } from '../../../hooks'

export const NewPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [password, setPassword] = useState<string>('')
  const [passwordRepeat, setPasswordRepeat] = useState<string>('')
  const correctoApi = useCorrectoApi()
  const { checkForCommonErrorMessage } = useCommonApiErrorMessage()

  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState<string>('')

  useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token') ?? '')
      searchParams.delete('token')
    }
    setSearchParams(searchParams)
  }, [])

  const submitHandler = () => {
    if (!password || !passwordRepeat || !token) return
    correctoApi
      .resetPassword(token, password)
      .then(response => {
        if (response.ok) {
          navigate('/new-password-success')
        } else {
          checkForCommonErrorMessage(
            response,
            t('Error al cambiar la contraseña. Por favor, intenta de nuevo.'),
          )
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }

  const repeatPasswordValidator = () =>
    passwordRepeat.length > 1
      ? {
          valid: password === passwordRepeat,
          message: t('Las contraseñas no coinciden'),
        }
      : {
          valid: false,
          message: t('La contraseña es obligatoria'),
        }

  return (
    <PublicPage>
      <PageHeader
        subtitle={t('Introduce la nueva contraseña de acceso a tu cuenta.')}
        title={t('Restablecer contraseña')}
      />
      <div className="max-w-[368px] w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Input
            label={t('Nueva contraseña *')}
            mode="password"
            onChange={e => {
              setPassword(e.target.value)
            }}
            placeholder={t('Escribe tu nueva contraseña')}
            requiredMessage={t('La contraseña es obligatoria')}
            value={password}
          />
          <Input
            label={t('Repetir contraseña *')}
            mode="password"
            onChange={e => {
              setPasswordRepeat(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setPasswordRepeat(e.currentTarget.value)
                submitHandler()
              }
            }}
            placeholder={t('Repite tu nueva contraseña')}
            validate={repeatPasswordValidator}
            value={passwordRepeat}
          />
          <Button
            className="-mt-1"
            label={t('Guardar nueva contraseña')}
            onClick={submitHandler}
          />
        </div>
      </div>
    </PublicPage>
  )
}
