import { useTranslation } from 'react-i18next'
import { Button, Typography } from 'ui'
import { useNavigate } from 'react-router-dom'
import { PublicPage, PageHeader } from '../../Components'

export const NewPasswordSuccess = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const primaryActionHandler = () => {
    navigate('/login')
  }

  return (
    <PublicPage>
      <PageHeader
        subtitle={t('Contraseña modificada.')}
        title={t('Restablecer contraseña')}
      />
      <div className="max-w-[368px] w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <Typography className="font-normal" variant="body2">
          {t(
            'La contraseña se ha modificado correctamente. Ya puedes iniciar sesión usando tu nueva contraseña.',
          )}
        </Typography>
        <Button label={t('Iniciar sesión')} onClick={primaryActionHandler} />
      </div>
    </PublicPage>
  )
}
