export const generateErrorColours = (
  opacity: number,
): Record<string, string> => {
  const colours = {
    terminology: `rgba(253, 244, 152, ${opacity.toString()})`,
    duplication: `rgba(253, 244, 152, ${opacity.toString()})`,
    inconsistency: `rgba(255, 165, 0, ${opacity.toString()})`,
    grammar: `rgba(255, 103, 103, ${opacity.toString()})`,
    style: `rgba(123, 192, 67, ${opacity.toString()})`,
    characters: `rgba(255, 165, 0, ${opacity.toString()})`,
    misspelling: `rgba(255, 103, 103, ${opacity.toString()})`,
    typographical: `rgba(0, 194, 255, ${opacity.toString()})`,
    formatting: `rgba(253, 244, 152, ${opacity.toString()})`,
    whitespace: `rgba(254, 178, 168, ${opacity.toString()})`,
    other: `rgba(255, 0, 0, ${opacity.toString()})`,
    'locale-violation': `rgba(101, 195, 186, ${opacity.toString()})`,
  }
  return colours
}

export const generateErrorColoursHEX = (): Record<string, string> => {
  const colours = {
    terminology: '#6E82FE',
    duplication: '#FAE94D',
    inconsistency: '#FF9500',
    grammar: '#FF0000',
    style: '#8CD6BD',
    characters: '#CC7C00',
    misspelling: '#C73C3C',
    typographical: '#3B98FF',
    formatting: '#FE80CC',
    whitespace: '#FFACA1',
    uncategorized: '#FF0000',
    other: '#FF0000',
  }
  return colours
}

export const ErrorColoursWithoutOpacity = generateErrorColours(0)
export const ErrorColoursWithOpacity = generateErrorColours(0.2)
export const HighlightErrorColoursHEX = generateErrorColoursHEX()

export const getIssueColorClassName = (issueType: string) => {
  switch (issueType) {
    case 'terminology':
      return 'text-issue-type-terminology'
    case 'duplication':
      return 'text-issue-type-duplication'
    case 'inconsistency':
      return 'text-issue-type-inconsistency'
    case 'grammar':
      return 'text-issue-type-grammar'
    case 'style':
      return 'text-issue-type-style'
    case 'characters':
      return 'text-issue-type-characters'
    case 'misspelling':
      return 'text-issue-type-misspelling'
    case 'typographical':
      return 'text-issue-type-typographical'
    case 'formatting':
      return 'text-issue-type-formatting'
    case 'whitespace':
      return 'text-issue-type-whitespace'
    case 'uncategorized':
      return 'text-issue-type-uncategorized'
    default:
      return 'text-issue-type-other'
  }
}
