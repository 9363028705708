import type {
  EditorConfig,
  LexicalNode,
  NodeKey,
  NodeSelection,
  RangeSelection,
  SerializedElementNode,
  Spread,
} from 'lexical'
import {
  addClassNamesToElement,
  removeClassNamesFromElement,
} from '@lexical/utils'
import {
  $applyNodeReplacement,
  $isElementNode,
  $isRangeSelection,
  ElementNode,
} from 'lexical'
import { ErrorColoursWithOpacity, HighlightErrorColoursHEX } from './utils'

export type SerializedSuggestionNode = Spread<
  {
    ids: string[]
    issueType: string
  },
  SerializedElementNode
>

export class SuggestionNode extends ElementNode {
  __ids: string[]
  __issueType: string
  __activeId?: string

  static getType(): string {
    return 'suggestion'
  }

  static clone(node: SuggestionNode): SuggestionNode {
    return new SuggestionNode(
      Array.from(node.__ids),
      node.__issueType,
      node.__key,
    )
  }

  static importDOM(): null {
    return null
  }

  static importJSON(serializedNode: SerializedSuggestionNode): SuggestionNode {
    const node = $createSuggestionNode(
      serializedNode.ids,
      serializedNode.issueType,
    )
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }

  exportJSON(): SerializedSuggestionNode {
    return {
      ...super.exportJSON(),
      issueType: this.getIssueType(),
      ids: this.getIDs(),
      type: 'suggestion',
      version: 1,
    }
  }

  constructor(
    ids: string[],
    issueType: string,
    activeId?: string,
    key?: NodeKey,
  ) {
    super(key)
    this.__ids = ids
    this.__issueType = issueType
    this.__activeId = activeId
  }

  createDOM(config: EditorConfig): HTMLElement {
    const issueType = this.getIssueType()

    const backgroundColorWithOpacity =
      ErrorColoursWithOpacity[issueType] || ErrorColoursWithOpacity.other
    const textColor =
      HighlightErrorColoursHEX[issueType] || HighlightErrorColoursHEX.other
    const element = document.createElement('span')
    element.setAttribute('data-suggestion', this.__ids.join(','))
    element.style.color = textColor
    element.style.textDecoration = 'underline'
    element.style.textDecorationThickness = '2px'
    element.style.textUnderlineOffset = '4px'
    addClassNamesToElement(element, config.theme.suggestion)

    if (this.__ids.length > 1) {
      addClassNamesToElement(element, config.theme.suggestionOverlap)
    }
    if (this.__activeId && this.__ids.includes(this.__activeId)) {
      addClassNamesToElement(element, 'active')
      element.style.backgroundColor = backgroundColorWithOpacity
    }
    return element
  }

  updateDOM(
    prevNode: SuggestionNode,
    element: HTMLElement,
    config: EditorConfig,
  ): boolean {
    const prevIDs = prevNode.__ids
    const nextIDs = this.__ids
    const prevIDsCount = prevIDs.length
    const nextIDsCount = nextIDs.length
    const overlapTheme = config.theme.suggestionOverlap

    if (prevIDsCount !== nextIDsCount) {
      if (prevIDsCount === 1) {
        if (nextIDsCount === 2) {
          addClassNamesToElement(element, overlapTheme)
        }
      } else if (nextIDsCount === 1) {
        removeClassNamesFromElement(element, overlapTheme)
      }
    }
    return false
  }

  hasID(id: string): boolean {
    const ids = this.getIDs()
    for (let i = 0; i < ids.length; i++) {
      if (id === ids[i]) {
        return true
      }
    }
    return false
  }

  getIDs(): string[] {
    const self = this.getLatest()
    return $isSuggestionNode(self) ? self.__ids : []
  }

  getIssueType(): string {
    const self = this.getLatest()
    return $isSuggestionNode(self) ? self.__issueType : 'other'
  }

  addID(id: string): void {
    const self = this.getWritable()
    if ($isSuggestionNode(self)) {
      const ids = self.__ids
      self.__ids = ids
      for (let i = 0; i < ids.length; i++) {
        // If we already have it, don't add again
        if (id === ids[i]) return
      }
      ids.push(id)
    }
  }

  deleteID(id: string): void {
    const self = this.getWritable()
    if ($isSuggestionNode(self)) {
      const ids = self.__ids
      self.__ids = ids
      for (let i = 0; i < ids.length; i++) {
        if (id === ids[i]) {
          ids.splice(i, 1)
          return
        }
      }
    }
  }

  insertNewAfter(
    selection: RangeSelection,
    restoreSelection = true,
  ): null | ElementNode {
    const element = this.getParentOrThrow().insertNewAfter(
      selection,
      restoreSelection,
    )
    if ($isElementNode(element)) {
      const suggestionNode = $createSuggestionNode(this.__ids, this.__issueType)
      element.append(suggestionNode)
      return suggestionNode
    }
    return null
  }

  canInsertTextBefore(): false {
    return false
  }

  canInsertTextAfter(): false {
    return false
  }

  canBeEmpty(): false {
    return false
  }

  isInline(): true {
    return true
  }

  extractWith_Child(
    _child: LexicalNode,
    selection: RangeSelection | NodeSelection,
    destination: 'clone' | 'html',
  ): boolean {
    if (!$isRangeSelection(selection) || destination === 'html') {
      return false
    }
    const anchor = selection.anchor
    const focus = selection.focus
    const anchorNode = anchor.getNode()
    const focusNode = focus.getNode()
    const isBackward = selection.isBackward()
    const selectionLength = isBackward
      ? anchor.offset - focus.offset
      : focus.offset - anchor.offset
    return (
      this.isParentOf(anchorNode) &&
      this.isParentOf(focusNode) &&
      this.getTextContent().length === selectionLength
    )
  }

  excludeFromCopy(destination: 'clone' | 'html'): boolean {
    return destination !== 'clone'
  }
}

export function $createSuggestionNode(
  ids: string[],
  issueType: string,
  activeId?: string,
): SuggestionNode {
  return $applyNodeReplacement(new SuggestionNode(ids, issueType, activeId))
}

export function $isSuggestionNode(
  node: LexicalNode | null,
): node is SuggestionNode {
  return node instanceof SuggestionNode
}
