import type { FC, ReactNode } from 'react'
import { clsx } from 'clsx'
import { Typography } from '../Typography/Typography'

interface TagProps {
  className?: string
  label: string | ReactNode
  variant: 'blue' | 'green' | 'gray'
  onClick?: () => void
}

export const Tag: FC<TagProps> = ({ className, label, variant, onClick }) => {
  const tagClassName = clsx(
    {
      'flex flex-col justify-center items-center rounded-lg h-[24px] px-2 w-fit':
        true,
      'bg-gradient-blue': variant === 'blue',
      'bg-gradient-green': variant === 'green',
      'bg-neutral-100': variant === 'gray',
    },
    className,
  )

  const renderTag = () => (
    <div className={tagClassName}>
      <Typography variant="small" className="font-bold">
        {label}
      </Typography>
    </div>
  )

  if (onClick) {
    return <button onClick={onClick}>{renderTag()}</button>
  }

  return renderTag()
}
