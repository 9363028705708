import type { FC, PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import { CorrectoApiClient } from '../clients/CorrectoApiClient'

type CorrectoApiContextType = CorrectoApiClient

const CorrectoApiContext = createContext<CorrectoApiContextType | null>(null)

export const CorrectoApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const api = new CorrectoApiClient()

  return (
    <CorrectoApiContext.Provider value={api}>
      {children}
    </CorrectoApiContext.Provider>
  )
}

export const useCorrectoApi = () => {
  return useContext(CorrectoApiContext)!
}
