import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from 'ui'
import chrome from '../../../public/assets/chrome.svg'
import { belongsExtensionCohort, trackUserAction } from '../../utils/amplitude'

export const ModalChrome: FC = () => {
  const [hasChromeInstalled, setHasChromeInstalled] = useState(true)
  const [modalChromeClosed, setModalChromeClosed] = useState(() => {
    return JSON.parse(localStorage.getItem('modalChromeClosed')!) as boolean
  })
  const { t } = useTranslation()
  const modalManager = useModal()
  const alreadyInstalledExtension = localStorage.getItem('extensionId')

  useEffect(() => {
    if (alreadyInstalledExtension) {
      setHasChromeInstalled(true)
    } else {
      belongsExtensionCohort().then(result => {
        setHasChromeInstalled(result)
      })
    }
  }, [])

  useEffect(() => {
    if (!modalChromeClosed && !hasChromeInstalled) {
      modalManager.openModal({
        body: (
          <div className="flex gap-4 text-7xl mt-6">
            <span>🙁</span>
            <img alt="vector " src={chrome} />
          </div>
        ),
        title: t('¿Aún no tienes nuestra extensión para Chrome instalada?'),
        subtitle: t(
          'Descárgala ahora y mejora tus textos en Gmail y en WhatsApp al instante.',
        ),
        type: 'complex',
        primaryAction: t('Añadir a Chrome gratis'),
        primaryActionType: 'primary',
        secondaryAction: t('Hacerlo más tarde'),
        textAlign: 'center',
        onPrimaryClick: () => {
          handleClickLink()
        },
        onClose: () => {
          handleCloseModal()
        },
      })
    }
  }, [modalChromeClosed, hasChromeInstalled])

  const handleClickLink = () => {
    handleCloseModal()
    trackUserAction({
      name: 'Download Chrome - Modal WebApp',
    })

    window.open(
      'https://chromewebstore.google.com/detail/correcto-herramienta-de-e/hllekbchnfikjpbdielihahenjmbpach',
      '_blank',
      'noopener',
    )
  }

  const handleCloseModal = () => {
    localStorage.setItem('modalChromeClosed', JSON.stringify(true))
    setModalChromeClosed(true)
  }

  return null
}
