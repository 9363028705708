import { Ilustration, Typography } from 'ui'
import { useTranslation } from 'react-i18next'

export const EmptyStateNoSuggestions = () => {
  const { t } = useTranslation()

  return (
    <div className="flex p-4 m-4 flex-col gap-4 items-center">
      <Typography className="font-bold" variant="body1">
        {t('¡Enhorabuena!')}
      </Typography>
      <Ilustration ilustration="document-checked" />
      <Typography className="font-normal" variant="small">
        {t('Tu texto esta')} <span className="font-bold">Correcto.</span>
      </Typography>
    </div>
  )
}
