import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const Step1: React.FC = () => {
  const { t } = useTranslation()
  const [text, setText] = useState(
    `Vamos a escrivir mal un tedto para que veas cómo funciona.
Puedes poner el cursor encima de los errores o pulsar el icono de Correcto.

Ahora, prueva a darle al icono de las estrellitas para parafrasear tu texto.`,
  )

  useEffect(() => {
    const textarea = document.querySelector('textarea')
    textarea?.focus()
  }, [])

  return (
    <div className="flex flex-col items-center justify-center mb-8">
      <h1 className="text-[40px] font-semibold text-blue-correcto-800">
        <span className="mr-3">🎉</span>
        {t('¡Correcto se ha instalado!')}
      </h1>
      <p className="text-2xl mt-4 mb-[40px] text-blue-correcto-800 font-normal">
        {t('Mira cómo funciona la corrección y el parafraseo')}
      </p>
      <textarea
        className="w-[957px] h-[264px] font-normal text-xl p-8 text-neutral-800 bg-white border
        border-neutral-200 rounded-lg focus:outline-none
        focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 resize-none"
        value={text}
        onChange={e => setText(e.target.value)}
      />
    </div>
  )
}

export default Step1
