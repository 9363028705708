interface Step3ButtonProps {
  imgSrc: string
  altText: string
  text: string
  onClick: () => void
}

const Step3Button: React.FC<Step3ButtonProps> = ({
  imgSrc,
  altText,
  text,
  onClick,
}) => (
  <button
    style={{
      borderRadius: '20px',
      boxShadow: '0px 4px 8px 0px rgba(204, 124, 0, 0.10)',
    }}
    className="w-[347px] h-[162px] p-[28px] flex flex-col justify-between items-center bg-white font-normal
    text-blue-correcto-800 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
    onClick={onClick}
  >
    <img src={imgSrc} alt={altText} className="h-[64px]" />
    <p>{text}</p>
  </button>
)

export default Step3Button
