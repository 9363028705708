import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ModalProvider } from 'ui'
import * as Sentry from '@sentry/react'
import App from './App'
import './index.css'
import './i18n'
import {
  CorrectoApiProvider,
  UserProvider,
  ToastProvider,
  OauthProvider,
} from './contexts'
import { ModalContainer } from './Components'
import { configSentry } from './utils/sentry'
import { configAmplitude } from './utils/amplitude'

configSentry()
configAmplitude()

function FallbackComponent() {
  return <div>Se ha producido un error. Intenta cargar de nuevo la pagina</div>
}

const myFallback = <FallbackComponent />

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <ToastProvider>
        <ModalProvider>
          <CorrectoApiProvider>
            <OauthProvider>
              <UserProvider>
                <BrowserRouter>
                  <App />
                  <ModalContainer />
                </BrowserRouter>
              </UserProvider>
            </OauthProvider>
          </CorrectoApiProvider>
        </ModalProvider>
      </ToastProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
