import type { FC, ReactNode } from 'react'
import { Typography } from 'ui'

interface PageHeaderProps {
  title: string
  subtitle?: string | ReactNode
}

export const PageHeader: FC<PageHeaderProps> = ({ title, subtitle }) => {
  return (
    <div className="text-center mb-4.5">
      <Typography
        className="text-blue-correcto-800 font-bold"
        variant="subtitle"
      >
        {title}
      </Typography>
      <div className="max-w-[508px] mx-auto ">
        <Typography
          className="text-blue-correcto-700 font-normal"
          variant="body2"
        >
          {subtitle}
        </Typography>
      </div>
    </div>
  )
}
