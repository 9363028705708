import { clsx } from 'clsx'
import type { FC, ReactNode } from 'react'
import { Button } from '../Button/Button'
import { Icon } from '../Icon/Icon'
import { Typography } from '../Typography/Typography'

interface ModalProps {
  title: string | ReactNode
  subtitle?: string | ReactNode
  primaryAction: string
  secondaryAction?: string
  onClose: () => void
  onPrimaryClick: () => void
  onCancelClick: () => void
  children: ReactNode
  className?: string
  primaryActionType?: 'primary' | 'danger' | 'premium'
}

export const ComplexModal: FC<ModalProps> = ({
  title,
  subtitle = '',
  primaryAction,
  onClose,
  onPrimaryClick,
  onCancelClick,
  secondaryAction = 'Cancelar',
  children,
  className,
  primaryActionType = 'primary',
}) => {
  const containerClassName = clsx(
    'flex flex-col bg-white rounded-2xl border border-neutral-300 shadow-2 w-[800px] p-6 relative',
    className ?? null,
  )

  return (
    <div className={containerClassName}>
      <div className="rounded-t-2xl relative overflow-hidden pt-4">
        <div className="flex flex-col items-center gap-2 text-blue-correcto-700 mt-6">
          {children}
          <Typography className="font-bold" component="h2" variant="subtitle">
            {title}
          </Typography>
          {subtitle ? (
            <Typography
              className="relative font-normal"
              component="p"
              variant="body1"
            >
              {subtitle}
            </Typography>
          ) : null}
        </div>
      </div>

      <div className="flex justify-center gap-2 rounded-b-2xl pt-8 pb-6">
        <Button
          label={secondaryAction}
          onClick={onCancelClick}
          variant="tertiary"
        />
        <Button
          label={primaryAction}
          onClick={onPrimaryClick}
          variant={primaryActionType}
        />
      </div>
      <button
        className="fill-neutral-600 absolute top-4 right-4"
        onClick={onClose}
        type="button"
      >
        <Icon icon="close" size="sm" />
      </button>
    </div>
  )
}
