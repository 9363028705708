import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { ProjectCard, ToastType, Typography, useModal } from 'ui'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EmptyState, PrivatePage, ToolBar } from '../../Components'
import { useCorrectoApi, useToast } from '../../contexts'
import type { Document } from '../../clients/CorrectoApiClient'
import { EMPTY_CONTENT } from '../../utils/constants'

export const Projects: FC = () => {
  const { documents, documentsCount, deleteDocument, createDocument } =
    useProjects()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const cardOnClickHandler = (document: Document) => () => {
    navigate(`/projects/${document.uuid}`)
  }

  return (
    <PrivatePage>
      <div className="flex flex-col gap-4 grow">
        <div className="flex h-full mx-10 flex-col gap-6">
          <ToolBar primaryButtonHandler={createDocument} />
          {documentsCount === undefined && null}
          {documentsCount === 0 && (
            <div className="flex h-full items-center justify-center bg-white rounded-2xl">
              <EmptyState
                content={t('Actualmente no tienes ningún proyecto creado.')}
                ilustration="empty"
                primaryAction={t('Nuevo proyecto')}
                primaryButtonHandler={createDocument}
              />
            </div>
          )}
          {documentsCount! > 0 && (
            <div className="flex gap-x-4 gap-y-6 items-start content-start flex-wrap">
              {documents.map(document => (
                <ProjectCard
                  key={document.uuid}
                  onActionClick={() => {
                    deleteDocument(document)
                  }}
                  onClick={cardOnClickHandler(document)}
                  title={document.title}
                >
                  {document.content}
                </ProjectCard>
              ))}
            </div>
          )}
        </div>
      </div>
    </PrivatePage>
  )
}

const useProjects = () => {
  const correctoApi = useCorrectoApi()
  const [documents, setDocuments] = useState<Document[]>([])
  const [documentsCount, setDocumentsCount] = useState<number>()

  const toastManager = useToast()
  const modalManager = useModal()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const getMyDocuments = () => {
    correctoApi
      .getMyDocuments()
      .then(response => {
        if (response.ok) {
          setDocumentsCount(response.body?.count)
          setDocuments(response.body?.results)
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t(
            'No hemos podido recuperar tus proyectos. Por favor, inténtalo de nuevo en unos minutos.',
          ),
        )
        throw error
      })
  }

  const deleteDocument = (documentUuid: string) => {
    correctoApi
      .deleteDocument(documentUuid)
      .then(response => {
        if (response.ok) {
          getMyDocuments()
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t(
            'No hemos podido eliminar el proyecto. Por favor, inténtalo de nuevo en unos minutos.',
          ),
        )
        throw error
      })
  }

  const deleteDocumentHandler = (document: Document) => {
    const title = document.title
    modalManager.openModal({
      body: (
        <Typography className="font-normal" variant="body2">
          {t('Vas a eliminar el proyecto')}{' '}
          <span className="font-semibold">{title}. </span>
          {t('Esta acción es irrevocable. ¿Estás conforme?')}
        </Typography>
      ),
      title: t('Eliminar proyecto'),
      type: 'simple',
      primaryAction: t('Eliminar'),
      primaryActionType: 'danger',
      secondaryAction: t('Cancelar'),
      textAlign: 'center',
      onPrimaryClick: () => {
        deleteDocument(document.uuid)
      },
    })
  }

  const createDocument = () => {
    const newDocumentName = t('Nuevo proyecto')
    correctoApi
      .createDocument(newDocumentName, '', JSON.parse(EMPTY_CONTENT) as object)
      .then(response => {
        if (response.ok) {
          navigate(`/projects/${response.body.uuid}`)
        } else {
          throw new Error(JSON.stringify(response))
        }
      })
      .catch((error: unknown) => {
        toastManager.showToast(
          ToastType.Danger,
          t(
            'No hemos podido crear un nuevo proyecto. Por favor, inténtalo de nuevo en unos minutos.',
          ),
        )
        throw error
      })
  }

  useEffect(getMyDocuments, [])

  return {
    documents,
    documentsCount,
    deleteDocument: deleteDocumentHandler,
    createDocument,
  }
}
