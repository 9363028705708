import type { PropsWithChildren, FC } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import type { Response, Auth, User } from '../../clients/CorrectoApiClient'
import { useCorrectoApi } from '../CorrectoApiProvider'

interface UserContext {
  user?: User | null
  featureFlags: FeatureFlags[]
  setUser: (user: User) => void
  login: (username: string, password: string) => Promise<Response<Auth>>
  logout: () => void
}
type FeatureFlags = 'HISTORY'

const UserContext = createContext<UserContext | null>(null)

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>()
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags[]>([])
  const correctoApi = useCorrectoApi()

  const login = async (username: string, password: string) => {
    const response = await correctoApi.login(username, password)

    return response
  }

  useEffect(() => {
    if (user?.email) {
      const features: FeatureFlags[] = []
      // isDev user
      if (
        [
          'premium.user@correcto.es',
          'free.user@correcto.es',
          'ionut.bostan@correcto.es',
          'gabriela.rodriguez@correcto.es',
        ].includes(user.email)
      ) {
        features.push('HISTORY')
      }
      // Correcto internal users
      if (user.email.endsWith('@correcto.es')) {
        features.push('HISTORY')
      }
      setFeatureFlags(features)
    } else {
      setFeatureFlags([])
    }
  }, [user])

  const logout = () => {
    correctoApi.logout()
    setUser(null)
  }

  return (
    <UserContext.Provider
      value={{ user, setUser, login, logout, featureFlags }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)!
}
