import type { FC } from 'react'
import { Button } from 'ui'
import {
  changeLangTo,
  useNavbarLanguage,
} from '../../Login/Components/Navbar/Navbar'
import { BannerChrome } from '../BannerChrome/BannerChrome'
import { ModalChrome } from '../ModalChrome/ModalChrome'

export const PrivateNavbar: FC = () => (
  <>
    <BannerChrome />
    <ModalChrome />
  </>
)

export const LanguageSwitcher = () => {
  const navbarLanguage = useNavbarLanguage()

  return (
    <Button
      label={navbarLanguage}
      onClick={changeLangTo(navbarLanguage)}
      size="small"
      variant="tertiary"
    />
  )
}
