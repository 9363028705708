import { useTranslation } from 'react-i18next'
import { Ilustration, Typography } from 'ui'
import { PublicPage } from '../../Components'

export const DesktopExperience = () => {
  const { t } = useTranslation()

  return (
    <PublicPage>
      <div className="flex flex-col gap-6 text-center items-center">
        <Typography
          className="text-blue-correcto-800 font-bold"
          variant="subtitle"
        >
          {t('Bienvenido a Correcto')}
        </Typography>
        <div>
          <Typography
            className="text-blue-correcto-700 font-normal"
            variant="body2"
          >
            {t(
              'Te hemos enviado un e-mail a tu cuenta de correo con un enlace',
            )}
          </Typography>
          <Typography
            className="text-blue-correcto-700 font-normal font-semibold pl-1"
            variant="body2"
          >
            {t('para que puedas activar tu cuenta de Correcto.')}
          </Typography>
        </div>
        <Typography
          className="text-blue-correcto-700 font-normal"
          variant="body2"
        >
          {t(
            'Estamos trabajando en poder ofrecerte una experiencia 100% optimizada para móvil.',
          )}
        </Typography>
        <div>
          <Typography
            className="text-blue-correcto-700 font-normal"
            variant="body2"
          >
            {t('De momento,')}
          </Typography>
          <Typography
            className="text-blue-correcto-700 font-normal font-semibold px-1"
            variant="body2"
          >
            {t('para obtener la mejor experiencia posible')}
          </Typography>
          <Typography
            className="text-blue-correcto-700 font-normal"
            variant="body2"
          >
            {t('te recomendamos que utilices una')}
          </Typography>
          <Typography
            className="text-blue-correcto-700 font-normal font-semibold pl-1"
            variant="body2"
          >
            {t('pantalla de escritorio.')}
          </Typography>
        </div>
        <Ilustration className="w-56" ilustration="desktop-experience" />
      </div>
    </PublicPage>
  )
}
