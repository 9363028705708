import React from 'react'

const Header: React.FC = () => (
  <div>
    <button
      className="text-2xl font-bold text-blue-correcto-800"
      onClick={() => {
        window.open('https://www.correctoai.com/', '_blank', 'noopener')
      }}
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          window.open('https://www.correctoai.com/', '_blank', 'noopener')
        }
      }}
    >
      Correcto.
    </button>
  </div>
)

export default Header
