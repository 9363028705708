import React from 'react'
import { clsx } from 'clsx'
import { Typography } from '../Typography/Typography'

interface LogoProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant: 'long-blue' | 'short-blue'
}

export const CorrectoLogo: React.FC<LogoProps> = ({
  variant,
  className,
  ...hrefProps
}) => {
  return (
    <a
      className={clsx('text-blue-correcto-800 pointer', className)}
      {...hrefProps}
    >
      <Typography className="font-bold" variant="subtitle">
        {variant === 'long-blue' ? 'Correcto.' : 'C.'}
      </Typography>
    </a>
  )
}
