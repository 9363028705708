import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { $getSelection, $setSelection, SELECTION_CHANGE_COMMAND } from 'lexical'
import { computePosition } from '@floating-ui/dom'
import { Button } from 'ui'
import { useTranslation } from 'react-i18next'
import { trackUserAction } from '../../../../../../utils/amplitude'

const DOM_ELEMENT = document.body

interface FloatingMenuPluginProps {
  onRephraseSuggestionClick: (selection: string) => void
}

export const FloatingMenuPlugin = ({
  onRephraseSuggestionClick,
}: FloatingMenuPluginProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [editor] = useLexicalComposerContext()
  const [coords, setCoords] = useState<FloatingMenuCoords>(undefined)
  const [selectedText, setSelectedText] = useState<string>()
  const [isSelectionCommandRegistered, setIsSelectionCommandRegistered] =
    useState(false)
  const [isClickEventListenerRegistered, setIsClickEventListenerRegistered] =
    useState(false)
  const { isPointerReleased } = usePointerInteractions()

  const calculatePosition = useCallback(() => {
    const domSelection = getSelection()

    if (!domSelection || domSelection?.type !== 'Range' || !ref.current) {
      setCoords(undefined)
      setSelectedText(undefined)
      return
    }

    const domRange = domSelection?.getRangeAt(0)
    computePosition(domRange, ref.current, { placement: 'bottom-start' })
      .then(pos => {
        setCoords({ x: pos.x, y: pos.y + 4 })
      })
      .catch(() => {
        setCoords(undefined)
      })
  }, [])

  const checkSelectionInEditor = () => {
    const selection = $getSelection()
    if (selection?.getTextContent()) {
      setSelectedText(selection?.getTextContent())
    } else {
      setSelectedText(undefined)
      setCoords(undefined)
    }
    return true
  }

  useEffect(() => {
    if (isSelectionCommandRegistered) return
    editor.registerCommand(SELECTION_CHANGE_COMMAND, checkSelectionInEditor, 0)
    setIsSelectionCommandRegistered(true)
  }, [])

  useEffect(() => {
    if (selectedText && !coords && isPointerReleased) {
      editor.update(() => {
        const selection = $getSelection()
        if (selection?.getTextContent()) {
          calculatePosition()
        }
      })
    }
  })

  useEffect(() => {
    if (isClickEventListenerRegistered) return
    addEventListener('click', () => {
      editor.update(() => {
        const selection = $getSelection()
        if (selection?.getTextContent()) {
          calculatePosition()
        }
      })
    })
    setIsClickEventListenerRegistered(true)
  })

  const onRephraseSuggestionClickHandler = () => {
    if (selectedText) {
      onRephraseSuggestionClick(selectedText)
      trackUserAction({
        name: 'Get new advanced suggestion for selection',
      })
      editor.update(() => {
        $setSelection(null)
      })
      setSelectedText(undefined)
      setCoords(undefined)
    }
  }

  return createPortal(
    <FloatingMenu
      coords={coords}
      onRephraseSuggestionClick={onRephraseSuggestionClickHandler}
      ref={ref}
    />,
    DOM_ELEMENT,
  )
}

type FloatingMenuCoords = { x: number; y: number } | undefined

interface FloatingMenuProps {
  coords: FloatingMenuCoords
  onRephraseSuggestionClick: () => void
}

const FloatingMenu = forwardRef<HTMLDivElement, FloatingMenuProps>(
  function FloatingMenu(props, ref) {
    const { coords, onRephraseSuggestionClick } = props
    const { t } = useTranslation()

    const shouldShow = useMemo(() => {
      return coords !== undefined
    }, [coords])

    return (
      <div
        aria-hidden={!shouldShow}
        className="flex flex-col bg-neutral-400 rounded-lg border-1 border-secondary hover:bg-neutral-600 rounded-lg gap-1 cursor-pointer"
        ref={ref}
        style={{
          position: 'absolute',
          top: coords?.y,
          left: coords?.x,
          visibility: shouldShow ? 'visible' : 'hidden',
          opacity: shouldShow ? 1 : 0,
        }}
      >
        <Button
          className="py-2 px-6 hover:bg-neutral-100 rounded-lg"
          label={t('Sugerencia de reescritura')}
          leftIcon="target"
          onClick={onRephraseSuggestionClick}
          size="small"
          variant="link"
        />
      </div>
    )
  },
)

const usePointerInteractions = () => {
  const [isPointerReleased, setIsPointerReleased] = useState(false)
  const [isPointerUpListenerRegistered, setIsPointerUpListenerRegistered] =
    useState(false)
  const [isPointerDownListenerRegistered, setIsPointerDownListenerRegistered] =
    useState(false)

  useEffect(() => {
    if (isPointerUpListenerRegistered && isPointerDownListenerRegistered) return

    if (!isPointerDownListenerRegistered) {
      addEventListener('pointerdown', () => {
        setIsPointerReleased(false)
      })
      setIsPointerDownListenerRegistered(true)
    }

    if (!isPointerUpListenerRegistered) {
      addEventListener('pointerup', () => {
        setIsPointerReleased(true)
      })
      setIsPointerUpListenerRegistered(true)
    }
  })

  return { isPointerReleased }
}
