import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useCorrectoApi, useUser } from '../../contexts'
import { useCommonApiErrorMessage } from '../../hooks'

export const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const correctoApi = useCorrectoApi()
  const { user, setUser, logout } = useUser()
  const { t } = useTranslation()
  const { checkForCommonErrorMessage } = useCommonApiErrorMessage()

  const getUser = () => {
    correctoApi
      .getUser()
      .then(response => {
        if (response.ok) {
          setUser(response.body)
          correctoApi.sendTokenToExtension(response.body)
        } else {
          checkForCommonErrorMessage(
            response,
            t('Se ha producido un error al obtener la información de usuario'),
          )
          logout()
          navigate('/login')
        }
      })
      .catch((error: unknown) => {
        throw error
      })
  }

  useEffect(() => {
    if (correctoApi.isAuthenticated()) {
      getUser()
    } else {
      logout()
      navigate('/login')
    }
  }, [])

  if (correctoApi.isAuthenticated()) {
    if (!user) return null
    return <>{children}</>
  }
  const url = location.pathname + location.search + location.hash
  return <Navigate state={{ next: url }} to="/login" />
}
