import { useTranslation } from 'react-i18next'
import { Typography } from 'ui'
import { PublicPage, PageHeader } from '../../Components'

export const AccountActivation = () => {
  const { t } = useTranslation()

  return (
    <PublicPage>
      <PageHeader
        subtitle={t(
          'No queda nada para que puedas empezar a utilizar Correcto.',
        )}
        title={t('Cuenta pendiente de activar')}
      />
      <div className="max-w-[368px] w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <Typography className="font-normal" variant="body2">
          {t(
            'Te hemos enviado un e-mail a tu cuenta de correo con un enlace para que puedas activar tu cuenta de Correcto.',
          )}
        </Typography>
      </div>
    </PublicPage>
  )
}
