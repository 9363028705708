import type { FC } from 'react'
import { Button } from 'ui'
import { useTranslation } from 'react-i18next'

interface ToolBarProps {
  primaryButtonHandler: () => void
}

export const ToolBar: FC<ToolBarProps> = ({ primaryButtonHandler }) => {
  const { t } = useTranslation()

  return (
    <div className="flex items-center">
      <div className="grow" />
      <Button
        label={t('Nuevo proyecto')}
        leftIcon="file-plus"
        onClick={primaryButtonHandler}
        size="small"
      />
    </div>
  )
}
