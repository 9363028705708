import { Trans, useTranslation } from 'react-i18next'
import type { FC } from 'react'
import { Range, Tag, Typography } from 'ui'
import type { Scores } from '../../../../clients/CorrectoApiClient'

const DIFFICULTY_LABELS = ['primaria', 'secundaria', 'universidad']

interface ScoringReportProps {
  scores: Scores
  overallScore?: number | string
}

export const ScoringReport: FC<ScoringReportProps> = ({
  scores,
  overallScore = '- / -',
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <div className="flex flex-col w-1/2 grow">
          <div className="px-4 py-8 bg-neutral-100 rounded-lg flex flex-col items-center mb-4 gap-2">
            <Tag
              className="p-4 text-blue-correcto-800 font-semibold"
              label={overallScore}
              variant="blue"
            />
            <Typography className="text-neutral-700 font-bold" variant="small">
              {t('Puntuación')}
            </Typography>
          </div>
          <Typography className="text-neutral-700" variant="small">
            <Trans
              components={{ bold: <b /> }}
              i18nKey="La puntuación se basa en la calidad de la escritura de tu texto. Puedes mejorar la puntuación  de tu texto aplicando nuestras sugerencias de escritura."
            />
          </Typography>
        </div>
        <div className="flex flex-col w-1/2 grow">
          <div className="px-4 py-8 bg-neutral-100 rounded-lg flex flex-col items-center mb-4 gap-2">
            <Tag
              className="p-4 text-blue-correcto-800 font-semibold"
              label={t(scores.clarity.display_text.es)}
              variant="blue"
            />
            <Typography className="text-neutral-700 font-bold" variant="small">
              {t('Claridad')}
            </Typography>
          </div>

          <Typography className="text-neutral-700" variant="small">
            <Trans
              components={{ bold: <b /> }}
              i18nKey="La claridad en un texto implica comunicar de manera comprensible, sin ambigüedades y con estructura organizada y legible para tus lectores."
            />
          </Typography>
        </div>
      </div>
      <div className="flex flex-col bg-neutral-100 p-4 rounded-lg">
        <div className="flex justify-between">
          <Typography
            className="text-blue-correcto-800 font-semibold"
            variant="body2"
          >
            {t('Comprensión')}
          </Typography>
          <Tag
            className="text-green-checker-900"
            label={t(scores.comprehension.display_text.es)}
            variant="green"
          />
        </div>
        <Range className="my-6" value={scores.comprehension.score} />
        <Typography variant="small">
          {t(
            'Utilizar una estructura clara y organizada en tus textos te permite tener mejor comprensión y a que tus lectores sigan el flujo de tus ideas de manera más sencilla',
          )}
        </Typography>
      </div>
      <div className="flex flex-col bg-neutral-100 p-4 rounded-lg">
        <div className="flex justify-between">
          <Typography
            className="text-blue-correcto-800 font-semibold"
            variant="body2"
          >
            {t('Legibilidad')}
          </Typography>
          <Tag
            className="text-green-checker-900"
            label={t(scores.readability.display_text.es)}
            variant="green"
          />
        </div>
        <Range className="my-6" value={scores.readability.score} />
        <Typography variant="small">
          {t(
            'Mantener una longitud de párrafos relativamente cortos ayuda a que los lectores no se sientan abrumados por grandes bloques de información.',
          )}
        </Typography>
      </div>
      <div className="flex flex-col bg-neutral-100 p-4 rounded-lg">
        <div className="flex justify-between">
          <Typography
            className="text-blue-correcto-800 font-semibold"
            variant="body2"
          >
            {t('Dificultad')}
          </Typography>
          <Tag
            className="text-green-checker-900"
            label={t(scores.difficulty.display_text.es)}
            variant="green"
          />
        </div>
        <Range
          className="mt-6"
          labels={DIFFICULTY_LABELS.map(label => t(label))}
          value={scores.difficulty.score}
        />
        <Typography variant="small">
          {t(
            'Es probable que tu texto sea comprensible para personas con estudios secundarios en adelante.',
          )}
        </Typography>
      </div>
    </div>
  )
}
