import React from 'react'

interface FooterProps {
  t: (key: string) => string
}

const Footer: React.FC<FooterProps> = ({ t }) => (
  <div className="mt-8">
    <div className="text-xl font-bold text-blue-correcto-800 ">Correcto.</div>
    <div className="text-blue-correcto-800 ">
      Copyright © {new Date().getFullYear()} Correcto.{' '}
      {t('Todos los derechos reservados.')}
    </div>
  </div>
)

export default Footer
