import { useMemo, type FC, type ReactNode } from 'react'
import { Typography } from 'ui'
import { useWindowSize } from '@uidotdev/usehooks'

interface HeaderSubtitleProps {
  firstLine: string
  secondLine: ReactNode
}

export const HeaderSubtitle: FC<HeaderSubtitleProps> = ({
  firstLine,
  secondLine,
}: HeaderSubtitleProps) => {
  const { width } = useWindowSize()

  const isSm = useMemo(() => {
    return (width && width < 768) as boolean
  }, [width])

  return (
    <>
      <Typography
        className="text-blue-correcto-700 font-normal"
        variant={isSm ? 'small' : 'body2'}
      >
        {firstLine}
      </Typography>
      <div>{secondLine}</div>
    </>
  )
}
