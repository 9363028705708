import React from 'react'
import { useTranslation } from 'react-i18next'

const Step2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center justify-center mb-8">
      <h2 className="text-[40px] font-semibold text-blue-correcto-800 mb-20">
        <span className="mr-3">✨</span>
        {t('Sugerencias de redacción')}
      </h2>

      <div className="flex gap-14">
        <img
          src="/assets/onboardingStep2.png"
          alt="Onboarding Step 2"
          className="w-auto max-w-xl h-auto"
        />
        <ul className="flex flex-col gap-4 flex-grow justify-center text-lg font-normal text-blue-correcto-800 list-disc min-w-[200px]">
          <li className="items-center">{t('Escribe tu texto')}</li>
          <li className="items-center">
            {t('Haz clic en el icono')}
            <img
              src="/assets/aiIconBg.svg"
              alt="AI Icon"
              className="inline-block mx-1"
            />
            <br />
            {t('o selecciona el texto')}
          </li>
          <li className="items-center">{t('Aplica la sugerencia')}</li>
        </ul>
      </div>
    </div>
  )
}

export default Step2
