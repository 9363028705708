import type { FC, PropsWithChildren } from 'react'
import { Navbar } from '../Navbar/Navbar'
import { PublicFooter } from '../PublicFooter/PublicFooter'

export const PublicPage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="content h-screen flex flex-col gap-10 md:gap-20 overflow-scroll">
      <Navbar />
      <div className="px-6 md:px-20 grow flex flex-col gap-4">{children}</div>
      <PublicFooter />
    </div>
  )
}
