import * as amplitude from '@amplitude/analytics-browser'
import { useWindowSize } from '@uidotdev/usehooks'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Button, Input, Typography } from 'ui'
import { ToastType } from 'ui/src/Toast/Toast'
import {
  AuthType,
  LOCAL_STORAGE_AUTH_TYPE,
} from '../../../clients/CorrectoApiClient'
import { useCorrectoApi, useOauth, useToast, useUser } from '../../../contexts'
import {
  MetricsCategory,
  MetricsName,
  trackEvent,
} from '../../../utils/amplitude'
import { EMAIL_REGEX_PATTERN } from '../../../utils/constants'
import { HeaderSubtitle, PageHeader, PublicPage } from '../../Components'
import { TermsAndConditions } from '../../Components/TermsAndConditions/TermsAndConditions'

export const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const userManager = useUser()
  const { showToast } = useToast()
  const { t } = useTranslation()
  const oauth = useOauth()
  const [searchParams] = useSearchParams()
  const isExtension = searchParams.get('extension') === 'true'
  const isOboarding = searchParams.get('onboarding') === 'true'
  const correctoApi = useCorrectoApi()
  const goToRememberPassword = () => {
    navigate('/reset-password')
  }

  const logInHandler = () => {
    userManager
      .login(email, password)
      .then(response => {
        if (response.ok) {
          amplitude.setUserId(response.body.user.uuid)

          trackEvent(MetricsCategory.AuthInteracted, {
            name: MetricsName.EmailLoggedIn,
            location: location.pathname,
            source: isExtension ? 'Chrome extension' : 'Webapp',
          })

          localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AuthType.Basic)
        } else {
          throw new Error(JSON.stringify(response))
        }

        if (isExtension) {
          correctoApi.sendTokenToExtension(response.body.user)
          isOboarding ? navigate('/onboarding') : window.close()
        } else {
          const { next }: { next: string } = location.state || {
            next: '/projects',
          }
          navigate(next)
        }
      })
      .catch(() => {
        localStorage.removeItem(LOCAL_STORAGE_AUTH_TYPE)

        showToast(
          ToastType.Danger,
          t('La autenticación ha fallado. Inténtalo de nuevo.'),
        )
      })
  }

  const googleSignInHandler = () => {
    oauth.oauthSignIn(
      isExtension ? 'chrome-extension' : 'web',
      'LOGIN',
      isOboarding,
    )
    localStorage.setItem(LOCAL_STORAGE_AUTH_TYPE, AuthType.Google)
  }

  return (
    <PublicPage>
      <PageHeader
        subtitle={
          <HeaderSubtitle
            firstLine={t(
              'Bienvenido de nuevo. Por favor: introduce tus datos de usuario.',
            )}
            secondLine={
              <LoginSecondLine
                isExtension={isExtension}
                isOnboarding={isOboarding}
              />
            }
          />
        }
        title={t('Iniciar sesión')}
      />

      <div className="max-w-[368px] w-[368px] mx-auto border-neutral-300 border rounded-lg bg-white p-6 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Input
            id="email"
            label={t('E-mail *')}
            data-testid="email-input"
            onChange={e => {
              setEmail(e.target.value)
            }}
            placeholder={t('Escribe tu e-mail')}
            validate={() =>
              email.length > 1
                ? {
                    valid: new RegExp(EMAIL_REGEX_PATTERN).test(email),
                    message: t('El formato del e-mail no es válido'),
                  }
                : {
                    valid: false,
                    message: t('El e-mail es obligatorio'),
                  }
            }
            value={email}
          />
          <Input
            id="password"
            label={t('Password *')}
            // data-testid="password-input"
            mode="password"
            onChange={e => {
              setPassword(e.target.value)
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setPassword(e.currentTarget.value)
                logInHandler()
              }
            }}
            placeholder={t('Escribe tu contraseña')}
            requiredMessage={t('La contraseña es obligatoria')}
            value={password}
          />
          <Button
            className="mt-[-3px] mb-[-1px]"
            label={t('Acceder')}
            onClick={logInHandler}
          />
          <Button
            label={t('¿Has olvidado tu contraseña?')}
            onClick={goToRememberPassword}
            size="small"
            variant="tertiary"
          />
        </div>
        <div className="flex flex-col gap-[16px] border-t border-neutral-500 pt-[24px]">
          <Button
            label={t('Accede con Google')}
            leftIcon="google"
            onClick={googleSignInHandler}
            variant="secondary"
          />
        </div>
        <TermsAndConditions />
      </div>
    </PublicPage>
  )
}

interface LoginSecondLineProps {
  isExtension: boolean
  isOnboarding: boolean
}

const LoginSecondLine: FC<LoginSecondLineProps> = ({
  isExtension,
  isOnboarding,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowSize()

  const isSm = useMemo(() => {
    return (width && width < 768) as boolean
  }, [width])

  return (
    <>
      <Button
        className="py-0 pl-0 pr-0 underline"
        label={t('Accede al registro')}
        onClick={() => {
          if (isExtension && isOnboarding) {
            navigate('/sign-up?extension=true&onboarding=true')
          } else if (isExtension && !isOnboarding) {
            navigate('/sign-up?extension=true')
          } else {
            navigate('/sign-up')
          }
        }}
        size={isSm ? 'small' : undefined}
        variant="link"
      />
      <Typography
        className="text-blue-correcto-700 font-normal pl-1"
        variant={isSm ? 'small' : 'body2'}
      >
        {t('si todavía no tiene una cuenta.')}
      </Typography>
    </>
  )
}
