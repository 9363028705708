import { clsx } from 'clsx'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Ilustration, Typography } from 'ui'
import { useUser } from '../../../../contexts'
import { trackUserAction } from '../../../../utils/amplitude'

interface PremiumUpsellProps {
  separator?: boolean
  className?: string
}

export const PremiumUpsell: FC<PremiumUpsellProps> = ({
  separator,
  className,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userManager = useUser()
  const containerClassName = clsx(className, {
    'flex gap-4 px-2': true,
    'border-t border-neutral-200': separator,
  })

  const goToPremium = () => {
    trackUserAction({
      name: 'Rephrase I want to upgrade to premium',
    })

    navigate('/premium')
  }

  if (userManager.user?.is_premium) return null

  return (
    <div className={containerClassName}>
      <Ilustration
        className="w-20 transform -scale-x-100 self-center"
        ilustration="group"
      />
      <div className="flex flex-col gap-4">
        <Typography variant="small">
          {t('¿Quieres seguir mejorando tu texto?')}
          {t('Hemos identificado')}
          <Typography className="px-1 font-semibold" variant="small">
            {t('más sugerencias de escritura')}
          </Typography>
          {t('incluidas en el plan Premium')}
        </Typography>
        <Button
          label={t('Quiero hacerme Premium')}
          leftIcon="crown"
          onClick={goToPremium}
          size="small"
          variant="premium"
        />
      </div>
    </div>
  )
}
